import { RootState } from "@/app/store"
import PostCard from "@/components/Cards/Post"
import GoalForm from "@/components/Forms/Goal"
import Button from "@/components/UI/Button"
import CustomModal from "@/components/UI/Modal"
import { fetchAllGoals } from "@/utils/updateRedux"
import { Divider } from "@mui/material"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

const AnalyticsCard = () => {
  const dispatch = useDispatch()
  const { total_goals, in_progress_goals, completed_goals, deleted_goals } =
    useSelector((state: RootState) => state.user)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [showCompleted, setShowCompleted] = useState<boolean>(false)
  const toggleModal = () => setIsOpen((prev) => !prev)
  const header = (
    <div className="flex flex-col gap-2 p-5">
      <p className="text-base font-semibold text-header">Analytics</p>
      <Divider />
    </div>
  )
  return (
    <>
      <CustomModal
        isOpen={isOpen}
        onClose={() => {
          toggleModal()
          setShowCompleted(false)
          fetchAllGoals(dispatch)
        }}
        title="ADD NEW GOAL"
        showHeader={!showCompleted}
      >
        <GoalForm
          onClose={() => {
            toggleModal()
            setShowCompleted(false)
          }}
          setShowCompleted={setShowCompleted}
          showCompleted={showCompleted}
        />
      </CustomModal>
      <PostCard
        header={header}
        widthClass="w-72"
        customHeightClass="h-card"
        justify="start"
      >
        <div className="flex flex-col justify-center px-5 gap-4 pb-5">
          <div className="flex items-center justify-between">
            <div className="flex flex-col w-32">
              <p className="text-base font-medium">{total_goals}</p>
              <p className="text-xs font-normal">Total Goals</p>
            </div>
            <div className="flex flex-col w-32">
              <p className="text-base font-medium">{completed_goals}</p>
              <p className="text-xs font-normal">Goals Completed</p>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex flex-col w-32">
              <p className="text-base font-medium">{in_progress_goals}</p>
              <p className="text-xs font-normal">In Progress</p>
            </div>
            <div className="flex flex-col w-32">
              <p className="text-base font-medium">{deleted_goals}</p>
              <p className="text-xs font-normal">Deleted</p>
            </div>
          </div>
          <Button
            name="Add New Goal"
            customClass="text-sm !rounded-50px !font-medium bg-parrotGreen"
            onClick={toggleModal}
          />
        </div>
      </PostCard>
    </>
  )
}

export default AnalyticsCard
