import Button from "@/components/UI/Button"
import InputField from "@/components/UI/InputField"
import { useForm, SubmitHandler } from "react-hook-form"
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  IconButton,
} from "@mui/material"
import { patchRequest, postRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import { fetchProfileData, handleError, updateToastState } from "@/utils/index"
import { Dispatch, SetStateAction, useState } from "react"
import { ToastType } from "@/types/toast"
import { GoalType } from "@/types/goal"
import { useDispatch, useSelector } from "react-redux"
import { fetchAllGoals } from "@/utils/updateRedux"
import CustomSwitch from "@/components/UI/Switch"
import addGoal from "@/assets/images/addgoal.png"
import { RootState } from "@/app/store"
import close from "@/assets/images/close-circle.svg"
import { DatePicker } from "antd"
import useScreenWidth from "@/hooks/useScreenWidth"
import dayjs from "dayjs"
import tick from "@/assets/images/tick.svg"

const { RangePicker } = DatePicker

enum GoalStatus {
  NotCompleted = 1,
  Completed = 0,
  InProgress = 2,
}

type Inputs = {
  title: string
  description: string
  receiveReminder: boolean
  date: [dayjs.Dayjs | null, dayjs.Dayjs | null]
  privacy: boolean
  attachment: FileList | null
  status: GoalStatus
}

interface GoalFormProps {
  goal?: GoalType
  onClose: () => void
  showCompleted: boolean
  setShowCompleted: Dispatch<SetStateAction<boolean>>
}

const GoalForm = ({
  goal,
  onClose,
  setShowCompleted,
  showCompleted,
}: GoalFormProps) => {
  const { privacy } = useSelector((state: RootState) => state.goal)
  const dispatch = useDispatch()
  const isMobile = useScreenWidth(640)
  const [activeField, setActiveField] = useState<string | null>(null)
  const [showFileUpload, setShowFileUpload] = useState<boolean>(
    goal?.photo_upload ? true : false,
  )
  const toggleShowFileUpload = () => setShowFileUpload((prev) => !prev)
  const handleFocus = (fieldName: string) => {
    setActiveField(fieldName)
  }
  const handleBlur = () => {
    setActiveField(null)
  }
  const defaultStartDate = goal?.start_date
    ? dayjs(new Date(goal.start_date))
    : null
  const defaultTargetDate = goal?.target_date
    ? dayjs(new Date(goal.target_date))
    : null

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm<Inputs>({
    defaultValues: {
      title: goal?.title || "",
      description: goal?.description || "",
      receiveReminder: false,
      date: [defaultStartDate, defaultTargetDate],
      privacy: goal?.is_private || privacy,
      attachment: null,
      status: goal?.current_status
        ? goal.current_status === "Completed"
          ? GoalStatus.Completed
          : goal.current_status === "In Progress"
          ? GoalStatus.InProgress
          : GoalStatus.NotCompleted
        : GoalStatus.NotCompleted,
    },
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const formData = new FormData()
    if (data.attachment) {
      Array.from(data.attachment).forEach((file) => {
        formData.append("photo_upload", file)
      })
    }
    formData.append("title", data.title)
    formData.append("description", data.description)
    formData.append("is_private", String(data.privacy))
    formData.append("start_date", data.date[0]?.format("YYYY-MM-DD") || "")
    formData.append("target_date", data.date[1]?.format("YYYY-MM-DD") || "")
    formData.append("receive_reminder", String(data.receiveReminder))
    if (data.attachment) {
      Array.from(data.attachment).forEach((file) => {
        formData.append("post", file)
      })
    }
    if (goal) {
      if (!showFileUpload && goal?.photo_upload) {
        formData.append("photo_upload", goal?.photo_upload)
      }
      formData.append("current_status", String(data.status))
      try {
        await patchRequest(`${getRoute("goals")}${goal.uuid}/`, formData)
        fetchProfileData(dispatch)
        if (setShowCompleted) {
          setShowCompleted(true)
        }
      } catch (err) {
        const error = handleError(err)
        updateToastState(
          dispatch,
          error.non_field_errors
            ? error.non_field_errors[0]
            : "Image size limit exceeded",
          ToastType.Error,
        )
      }
    } else {
      try {
        await postRequest(getRoute("goals"), formData)
        fetchProfileData(dispatch)
        if (setShowCompleted) {
          setShowCompleted(true)
        }
      } catch (err) {
        const error = handleError(err)
        updateToastState(
          dispatch,
          error.non_field_errors
            ? error.non_field_errors[0]
            : "Image size limit exceeded",
          ToastType.Error,
        )
      }
    }
  }

  const getPreviewUrl = (file: File | null) => {
    return file ? URL.createObjectURL(file) : undefined
  }

  const attachment = watch("attachment") as FileList | null

  const removeImage = (fileToRemove: File) => {
    if (attachment) {
      const updatedAttachment = Array.from(attachment).filter(
        (file) => file !== fileToRemove,
      )
      const dataTransfer = new DataTransfer()
      updatedAttachment.forEach((file) => dataTransfer.items.add(file))
      setValue("attachment", null)
    }
  }

  return (
    <>
      {showCompleted ? (
        <div className="flex flex-col items-center justify-center h-full fade-in">
          <img src={tick} />
          <div className="flex flex-col items-center gap-8">
            <p className="font-medium text-2xl text-center">
              Goal {goal ? "Updated" : "Added"}
            </p>
            <Button
              name="Cancel"
              customClass="bg-white border-grey-1 px-10"
              customTextClass="!text-header"
              onClick={() => {
                onClose()
                fetchAllGoals(dispatch)
              }}
            />
          </div>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col items-center gap-6"
        >
          {goal?.photo_upload && showFileUpload && (
            <div className="pt-2">
              <div className="relative">
                <img
                  src={goal?.photo_upload}
                  alt="Preview"
                  height={200}
                  width={200}
                  className="rounded-md"
                />
                <div className="absolute top-0 right-5">
                  <IconButton onClick={toggleShowFileUpload}>
                    <img src={close} />
                  </IconButton>
                </div>
              </div>
            </div>
          )}
          {attachment && (
            <div className="pt-2">
              {Array.from(attachment).map((file) => (
                <div key={file.name} className="relative">
                  <img
                    src={getPreviewUrl(file)}
                    alt="Preview"
                    height={200}
                    width={200}
                    className="rounded-md"
                  />
                  <div className="absolute top-0 right-5">
                    <IconButton onClick={() => removeImage(file)}>
                      <img src={close} />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div
            className={`flex items-center ${
              (attachment || showFileUpload) && "hidden"
            }`}
          >
            <label htmlFor="attachment">
              <input
                {...register("attachment")}
                id="attachment"
                type="file"
                className="hidden"
              />
              <img src={addGoal} className="cursor-pointer" />
            </label>
          </div>
          <div className="flex flex-col md:flex-row px-5 items-center gap-4 w-full">
            <InputField
              control={control}
              name="title"
              placeholder="Title"
              type="text"
              hasError={!!errors?.title}
              required={true}
              width="w-6/12"
              customClass="!text-black !override-autofill"
              padding="p-3"
            />
            <InputField
              control={control}
              hasError={false}
              name="date"
              required
              wrapperCustomClass="border-none !px-0"
              customClass="!py-[13px] rounded-10px"
              onFocus={() => handleFocus("date")}
              onBlur={handleBlur}
            >
              <RangePicker
                className={`w-full ${
                  activeField ? "!border-grey-1" : "!border-grey-200"
                } !whiteBg ant-picker-active-bar`}
                placement="topRight"
                popupClassName={
                  isMobile
                    ? "w-full ant-picker-panel-layout scrollbar-hide overflow-y-hidden"
                    : ""
                }
                value={
                  watch("date") as [dayjs.Dayjs | null, dayjs.Dayjs | null]
                }
                getPopupContainer={(triggerNode: any) => {
                  return triggerNode
                }}
              />
            </InputField>
          </div>
          <div className="flex flex-col md:flex-row px-5 items-start gap-4 w-full">
            <InputField
              control={control}
              name="description"
              placeholder="Description"
              type="text"
              hasError={!!errors?.description}
              required={true}
              multiline={true}
              width="w-full"
              rows={4}
              customClass="!text-black !override-autofill"
            />
            <InputField
              name="privacy"
              control={control}
              hasError={!!errors?.privacy}
              required={false}
              label="Privacy"
              isRow={true}
              helperText="Privacy is set to public by default, on the toggle button to set to private"
              hasBorder={false}
            >
              <CustomSwitch />
            </InputField>
          </div>
          <div className="w-full">
            {goal && (
              <div className="px-5 float-left">
                <InputField
                  control={control}
                  name="status"
                  label="Status"
                  hasError={!!errors?.status}
                  required={false}
                  hasBorder={false}
                  gap="gap-2"
                >
                  <RadioGroup
                    defaultValue={watch("status")}
                    className="!flex-row"
                  >
                    <FormControlLabel
                      value={GoalStatus.NotCompleted}
                      control={<Radio size="small" />}
                      label={<p className="text-sm">Not Completed</p>}
                    />
                    <FormControlLabel
                      value={GoalStatus.Completed}
                      control={<Radio size="small" />}
                      label={<p className="text-sm">Completed</p>}
                    />
                    <FormControlLabel
                      value={GoalStatus.InProgress}
                      control={<Radio size="small" />}
                      label={<p className="text-sm">In Progress</p>}
                    />
                  </RadioGroup>
                </InputField>
              </div>
            )}
          </div>

          <div className="w-full">
            <Divider />
          </div>
          <div className="w-full pb-5 px-5">
            <div className="flex items-center gap-3.5 md:gap-4 float-right">
              <Button
                name="Cancel"
                type="button"
                onClick={onClose}
                customClass="!px-8 md:!px-10 border border-grey-1 bg-white"
                customTextClass="!text-grey-1"
              />
              <Button
                name={`${goal ? "Update" : "Add"}`}
                type="submit"
                loading={isSubmitting}
                loaderColor="white"
                customClass={`${goal ? "px-9" : "px-12"}`}
              />
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export default GoalForm
