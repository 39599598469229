import { getRoute } from "@/api/routes"
import { patchRequest } from "@/api/index"
import { Dispatch } from "react"
import { ToastType } from "@/types/toast"
import { fetchProfileData, updateToastState } from "@/utils/index"

export const updateProfile = async (
  data: any,
  dispatch: Dispatch<any>,
  customAction?: () => void,
  updateLoading?: boolean,
) => {
  const loadingKey = updateLoading ? "updateProfile" : undefined
  try {
    await patchRequest(getRoute("updateProfile"), data, dispatch, loadingKey)
    updateToastState(dispatch, "Changes saved", ToastType.Success)
    fetchProfileData(dispatch)
    if (customAction) {
      customAction()
    }
  } catch (err) {
    updateToastState(dispatch, "Failed to save changes", ToastType.Error)
  }
}
