export type ToastInfoType = {
  isOpen: boolean
  onClose: () => void
  message: string
  type: "error" | "success"
}

export enum ToastType {
  Success = "success",
  Error = "error",
}
