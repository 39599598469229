import { ReactNode } from "react"
import backgroundRectangle from "@/assets/images/backgroundRectangle.svg"
import logo from "@/assets/images/goalLogo.svg"
import AuthCard from "@/components/Cards/Auth"
import { authCards } from "@/utils/auth"

interface AuthLayoutProps {
  children: ReactNode
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <div className="h-screen w-screen flex items-center">
      <div className="h-full hidden xl:flex lg:flex-col xl:w-6/12 bg-primary justify-center px-16 overflow-y-scroll scrollbar-hide">
        <div className="flex flex-col gap-4 justify-start pb-5 w-10/12">
          <p className="font-semibold text-white text-3.5xl leading-normal">
            Empower Your Journey
          </p>
          <p className="font-normal text-sm text-white leading-normal">
            Set, update, keep track and achieve goals with ease
          </p>
        </div>
        <div className="flex flex-col justify-center gap-8">
          {authCards.map((card, index) =>
            index % 2 !== 0 ? (
              <div className="flex items-center" key={card.name}>
                <AuthCard card={card} index={index} />
                <div className="w-4/12" />
              </div>
            ) : (
              <div className="flex items-center" key={card.name}>
                <div className="w-4/12" />
                <AuthCard card={card} index={index} />
              </div>
            ),
          )}
        </div>
      </div>
      <div className="h-full w-full xl:w-6/12 flex flex-col justify-center bg-white gap-8 lg:gap-0">
        <div className="px-16 flex mt-1 justify-center lg:justify-start">
          <img src={logo} height={60} width={60} />
        </div>
        {children}
      </div>
    </div>
  )
}

export default AuthLayout
