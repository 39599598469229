import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit"

interface LoadingPayload<T> {
  key: keyof T
  value: T[keyof T]
}

export interface LoadingState {
  goalList: boolean
  route: boolean
  feed: boolean
  profile: boolean
  commentList: boolean
  goalPostComment: boolean
  friendList: boolean
  goalPost: boolean
  friendRequestList: boolean
  friendRequestRespond: boolean
  userSearch: boolean
  friendRequestSend: boolean
  notifications: boolean
  friendProfile: boolean
  register: boolean
  goalPostLike: boolean
  updateProfile: boolean
  resetPassword: boolean
  friendGoalPostList: boolean
  readAllNotifications: boolean
  unfriend: boolean
}

const initialState: LoadingState = {
  goalList: false,
  route: true,
  feed: false,
  profile: false,
  commentList: false,
  goalPostComment: false,
  friendList: false,
  goalPost: false,
  friendRequestList: false,
  friendRequestRespond: false,
  userSearch: false,
  friendRequestSend: false,
  notifications: false,
  friendProfile: false,
  register: false,
  goalPostLike: false,
  updateProfile: false,
  resetPassword: false,
  friendGoalPostList: false,
  readAllNotifications: false,
  unfriend: false,
}

export const loadingSlice: Slice<LoadingState> = createSlice({
  name: "loading",
  initialState,
  reducers: {
    toggleLoading: (
      state,
      action: PayloadAction<LoadingPayload<LoadingState>>,
    ) => {
      const { key, value } = action.payload
      return {
        ...state,
        [key]: value,
      }
    },
  },
})

export const { toggleLoading } = loadingSlice.actions
export default loadingSlice.reducer
