import { CircularProgress } from "@mui/material"

interface LoadingProps {
  color?: string
  size?: string
}

const Loading = ({ color, size = "1.4em" }: LoadingProps) => {
  return (
    <div className="h-full justify-center items-center flex">
      <CircularProgress size={size} sx={{ color }} />
    </div>
  )
}

export default Loading
