import { getRoute } from "@/api/routes"
import { getRequest } from "@/api/index"
import { updateGoalList } from "@/slice/goal"
import { updateFriendRequestList } from "@/slice/friend"
import { updateSelectedNotificationType } from "@/slice/notifications"

export const fetchAllGoals = async (
  dispatch: React.Dispatch<any>,
  uuid?: string,
  extendedUrl?: string,
  friendGoal?: boolean,
  pageNumber?: string | undefined,
) => {
  try {
    const response = await getRequest(
      friendGoal
        ? `${getRoute("friendGoalList")}${extendedUrl}${
            pageNumber ? `?page=${pageNumber}` : ""
          }`
        : uuid
        ? `${getRoute("goals")}${extendedUrl?.split("/")[1]}${
            pageNumber ? `?page=${pageNumber}` : ""
          }`
        : `${getRoute("goals")}${pageNumber ? `?page=${pageNumber}` : ""}`,
      {},
      dispatch,
      "goalList",
    )
    dispatch(
      updateGoalList(
        uuid
          ? { count: 0, next: "", previous: "", results: [response] }
          : response,
      ),
    )
    dispatch(updateSelectedNotificationType(""))
  } catch (err) {
    dispatch(updateGoalList({ count: 0, previous: "", next: "", results: [] }))
    dispatch(updateSelectedNotificationType(""))
  }
}

export const fetchFriendRequestList = async (dispatch: React.Dispatch<any>) => {
  try {
    const response = await getRequest(
      getRoute("friendRequestList"),
      {},
      dispatch,
      "friendRequestList",
    )
    dispatch(updateFriendRequestList(response))
  } catch (err) {}
}
