import { FriendType } from "@/types/friend"
import { FriendRequestType } from "@/types/friendRequest"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface FriendState {
  friendList: FriendType[] | []
  friendRequestList: FriendRequestType[] | []
}

const initialState: FriendState = {
  friendList: [],
  friendRequestList: [],
}

export const friendSlice = createSlice({
  name: "friend",
  initialState,
  reducers: {
    updateFriendList: (state, action: PayloadAction<FriendType[]>) => {
      state.friendList = action.payload
    },
    updateFriendRequestList: (
      state,
      action: PayloadAction<FriendRequestType[]>,
    ) => {
      state.friendRequestList = action.payload
    },
  },
})

export const { updateFriendList, updateFriendRequestList } = friendSlice.actions
export default friendSlice.reducer
