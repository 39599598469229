import { postRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import { RootState } from "@/app/store"
import { NotificationsType, NotificationType } from "@/types/notification"
import { useDispatch, useSelector } from "react-redux"
import Loading from "@/components/UI/Loading"
import userImg from "@/assets/images/noUser.svg"
import { matchingFriend } from "@/utils/friend"
import { useNavigate } from "react-router-dom"
import { ToastType } from "@/types/toast"
import CircleIcon from "@mui/icons-material/Circle"
import { getDuration } from "@/utils/date"
import { updateToastState } from "@/utils/index"
import { updateSelectedNotificationType } from "@/slice/notifications"
import Button from "@/components/UI/Button"

interface NotificationsProps {
  notifications: NotificationsType
  onClose: () => void
  fetchAllNotifications: (pageNumber?: string | null | undefined) => void
}

const Notifications = ({
  notifications,
  onClose,
  fetchAllNotifications,
}: NotificationsProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    loading: { notifications: notificationsLoading },
    friend: { friendList },
  } = useSelector((state: RootState) => state)
  const readNotification = async (notification: NotificationType) => {
    dispatch(
      updateSelectedNotificationType(
        notification.action_performed === "Posted" ? "friend" : "own",
      ),
    )
    onClose()
    navigate(
      `/goals/${notification.from_user}/${notification.goal_details.uuid}`,
    )
    try {
      await postRequest(getRoute("readNotification"), {
        uuid: notification.uuid,
      })
      updateToastState(dispatch, "Notification read", ToastType.Success)
      fetchAllNotifications()
    } catch (err) {
      updateToastState(dispatch, "Failed to read notification", ToastType.Error)
    }
  }
  const readAllNotifications = async () => {
    try {
      await postRequest(
        getRoute("readAllNotifications"),
        {},
        dispatch,
        "readAllNotifications",
      )
      updateToastState(dispatch, "All notifications read", ToastType.Success)
      fetchAllNotifications()
    } catch (err) {
      updateToastState(
        dispatch,
        "Failed to read notifications",
        ToastType.Error,
      )
    }
  }
  const fetchNext = async () => {
    fetchAllNotifications(
      notifications.next && notifications.next?.split("page=")[1],
    )
  }
  const fetchPrevious = async () => {
    fetchAllNotifications(
      notifications.previous && notifications.previous?.split("page=")[1],
    )
  }
  return (
    <>
      {notificationsLoading ? (
        <div className="flex justify-center w-56 md:w-full py-5">
          <Loading key="loading" />
        </div>
      ) : notifications.results.length > 0 ? (
        <div className="max-h-56 flex flex-col gap-2 p-3">
          <div className="flex items-center justify-between">
            <p className="font-semibold text-sm">Notifications</p>
            <p
              className="underline text-primary font-medium text-xs cursor-pointer"
              onClick={() => readAllNotifications()}
            >
              Mark All As Read
            </p>
          </div>
          <div className="border border-grey-2 h-1 my-1" />
          {notifications.results.map((notification) => {
            const matchingFriendData = matchingFriend(
              friendList,
              notification.from_user,
            )

            return (
              <div
                key={notification.uuid}
                className={`flex gap-2 p-3 mb-2 w-76 hover:bg-grey-200 rounded-10px cursor-pointer ${
                  !notification.is_read && "bg-grey-2"
                }`}
                onClick={() => readNotification(notification)}
              >
                <div className="flex gap-2 items-center">
                  {!notification.is_read && (
                    <CircleIcon className="text-primary !text-10" />
                  )}
                  <div className="w-7 h-7 shadow-2xl">
                    <img
                      src={matchingFriendData?.image ?? userImg}
                      className="rounded-full w-full h-full"
                    />
                  </div>
                </div>
                <div
                  className={`flex justify-between items-center ${
                    notification.is_read ? "w-10/12" : "w-9/12 xs:w-10/12"
                  }`}
                >
                  <p className="font-medium text-sm text-header w-8/12">
                    {matchingFriendData?.name}{" "}
                    <span className="font-semibold text-xs text-grey-1">
                      {notification.action_performed === "Posted"
                        ? `${notification.action_performed.toLowerCase()} an update`
                        : `${
                            notification.action_performed === "Comment"
                              ? "commented"
                              : notification.action_performed === "Like"
                              ? "liked"
                              : ""
                          } ${
                            notification.action_performed !== "Like" ? "on" : ""
                          } this goal`}
                    </span>
                  </p>
                  <div className="flex flex-col">
                    <p className="text-8 font-normal md:text-10 text-right">
                      {getDuration(notification.date_created)}
                    </p>
                    <p className="text-primary underline cursor-pointer text-8 md:text-10 font-semibold text-right">
                      View Goal
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
          <div>
            <div className="flex gap-2 justify-center mb-3">
              {notifications.next ? (
                <Button
                  name="Load More"
                  iconWrapperGap="gap-0"
                  isIconAfter
                  customTextClass="!text-primary !font-semibold text-sm"
                  customClass="pl-5 py-2 bg-white border-none"
                  onClick={fetchNext}
                />
              ) : (
                <p className="text-sm font-semibold">No more notifications</p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center p-3 gap-4 justify-center w-76">
          <p key="no-notifications" className="text-sm font-normal">
            No notifications to show
          </p>
        </div>
      )}
    </>
  )
}

export default Notifications
