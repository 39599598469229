import { getRoute } from "@/api/routes"
import { postRequest } from "@/api/index"
import { Dispatch, SetStateAction } from "react"
import { ToastType } from "@/types/toast"
import { updateToastState } from "."

export const likePost = async (
  likeState: boolean,
  updateLikeState: Dispatch<SetStateAction<boolean>>,
  increaseCount: () => void,
  decreaseCount: () => void,
  uuid: string,
  action: string,
  dispatch: Dispatch<any>,
  setShowAnimation: Dispatch<SetStateAction<boolean>>,
) => {
  updateLikeState((prev) => !prev)
  if (!likeState) {
    increaseCount()
  } else {
    decreaseCount()
  }
  try {
    const response = await postRequest(getRoute("goalPostLike"), {
      uuid,
      action,
    })
    setShowAnimation(true)
    setTimeout(() => setShowAnimation(false), 500)
  } catch (err) {
    updateToastState(dispatch, "Failed to like post", ToastType.Error)
  }
}
