import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { RootState } from "@/app/store"
import { authenticate } from "@/slice/auth"
import { setAuthToken } from "@/api/index"
import LoginScreen from "@/screens/Login"
import RegisterScreen from "@/screens/Register"
import GoalScreen from "@/screens/Goal"
import Layout from "@/components/Layout"
import SecureLS from "secure-ls"
import ProfileScreen from "@/screens/Profile"
import FriendGoalsScreen from "@/screens/FriendGoals"
import MyNetworkScreen from "@/screens/MyNetwork"
import ProfileStats from "@/components/ProfileStats"
import UserProfileScreen from "@/screens/Profile/User"
import { fetchFriendList, fetchProfileData } from "@/utils/index"
import TermsAndConditionsScreen from "@/screens/TermsAndConditions"
import PrivacyPolicyScreen from "@/screens/PrivacyPolicy"
import Toast from "@/components/UI/Toast"
import { closeToast } from "@/slice/toast"

export default function App() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const ls = new SecureLS()
  const {
    auth: { isAuthenticated },
    layout: { screen },
    toast: { toastInfo },
  } = useSelector((state: RootState) => state)

  useEffect(() => {
    const accessToken = ls.get("accessToken")
    const refreshToken = ls.get("refreshToken")
    if (accessToken && refreshToken) {
      setAuthToken(accessToken)
      dispatch(
        authenticate({ isAuthenticated: true, accessToken, refreshToken }),
      )
    }
    if (isAuthenticated) {
      fetchProfileData(dispatch)
      fetchFriendList(dispatch)
    } else {
      navigate("/login")
    }
  }, [isAuthenticated])
  return (
    <>
      <Toast
        isOpen={toastInfo?.isOpen ?? false}
        onClose={() => dispatch(closeToast())}
        message={toastInfo?.message ?? ""}
        type={toastInfo?.type ?? "error"}
      />
      <Routes>
        {isAuthenticated ? (
          <>
            <Route
              path="/legal/termsAndConditions"
              element={<TermsAndConditionsScreen />}
            />
            <Route
              path="/legal/privacyPolicy"
              element={<PrivacyPolicyScreen />}
            />
            <Route
              path="/goals"
              element={
                <Layout
                  leftColumnChildren={<GoalScreen />}
                  rightColumnChildren={screen.rightColumnChildren}
                  leftColumnWidthClass={screen.leftColumnWidthClass}
                />
              }
            />
            <Route
              path="/goals/:userUuid/:goalUuid"
              element={
                <Layout
                  leftColumnChildren={<GoalScreen />}
                  rightColumnChildren={screen.rightColumnChildren}
                  leftColumnWidthClass={screen.leftColumnWidthClass}
                />
              }
            />
            <Route
              path="/friendGoals"
              element={
                <Layout
                  leftColumnChildren={<FriendGoalsScreen />}
                  rightColumnChildren={screen.rightColumnChildren}
                  leftColumnWidthClass={screen.leftColumnWidthClass}
                />
              }
            />
            <Route
              path="/myNetwork"
              element={
                <Layout
                  leftColumnChildren={<MyNetworkScreen />}
                  leftColumnWidthClass="w-full"
                />
              }
            />
            <Route
              path="/user/:uuid"
              element={
                <Layout
                  leftColumnChildren={<UserProfileScreen />}
                  rightColumnChildren={screen.rightColumnChildren}
                  leftColumnWidthClass={screen.leftColumnWidthClass}
                />
              }
            />
            <Route
              path="/profile"
              element={
                <Layout
                  rightColumnChildren={<ProfileStats />}
                  leftColumnChildren={<ProfileScreen />}
                />
              }
            />
            <Route path="/*" element={<Navigate to="/goals" />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/*" element={<Navigate to="/login" />} />
            <Route path="/register" element={<RegisterScreen />} />
          </>
        )}
      </Routes>
    </>
  )
}
