import axios, { AxiosError } from "axios"
import { getRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import { updateProfile } from "@/slice/user"
import { Dispatch } from "react"
import { ToastType } from "@/types/toast"
import { updateToastInfo } from "@/slice/toast"
import { updateFriendList } from "@/slice/friend"

export const handleError = (error: any) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError
    if (axiosError.response) {
      return axiosError.response.data
    } else if (axiosError.request) {
      return axiosError.request
    } else {
      return axiosError.message
    }
  } else {
    return error
  }
}

export const fetchProfileData = async (dispatch: Dispatch<any>) => {
  try {
    const response = await getRequest(
      getRoute("profile"),
      {},
      dispatch,
      "profile",
    )
    dispatch(updateProfile(response))
  } catch (err) {}
}

export const updateToastState = (
  dispatch: Dispatch<any>,
  message: string,
  type: ToastType,
) => {
  dispatch(
    updateToastInfo({
      isOpen: true,
      onClose: () => null,
      message: message,
      type: type,
    }),
  )
}

export const fetchFriendList = async (dispatch: Dispatch<any>) => {
  try {
    const response = await getRequest(
      getRoute("friendList"),
      {},
      dispatch,
      "friendList",
    )
    dispatch(updateFriendList(response))
  } catch (err) {}
}
