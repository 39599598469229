import { postRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import Button from "@/components/UI/Button"
import InputField from "@/components/UI/InputField"
import { GoalType } from "@/types/goal"
import { ToastType } from "@/types/toast"
import { IconButton } from "@mui/material"
import { useForm, SubmitHandler } from "react-hook-form"
import close from "@/assets/images/close-circle.svg"
import postImage from "@/assets/images/postImage.svg"
import { fetchAllGoals } from "@/utils/updateRedux"
import { useDispatch } from "react-redux"
import { handleError, updateToastState } from "@/utils/index"
import { Dispatch, SetStateAction } from "react"
import tick from "@/assets/images/tick.svg"

interface PostFormProps {
  goal: GoalType
  onClose: () => void
  showCompleted: boolean
  setShowCompleted: Dispatch<SetStateAction<boolean>>
}

type Inputs = {
  post: string
  date: string
  attachment: FileList | null
}

const PostForm = ({
  goal,
  onClose,
  setShowCompleted,
  showCompleted,
}: PostFormProps) => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      post: "",
      date: "",
      attachment: null,
    },
  })
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const formData = new FormData()
    formData.append("goal", goal.uuid)
    if (data.attachment) {
      Array.from(data.attachment).forEach((file) => {
        formData.append("images", file)
      })
    }
    formData.append("description", data.post)
    try {
      await postRequest(getRoute("goalPost"), formData)
      setShowCompleted(true)
    } catch (err) {
      const error = handleError(err)
      updateToastState(
        dispatch,
        error.non_field_errors
          ? error.non_field_errors[0]
          : "Image size limit exceeded",
        ToastType.Error,
      )
    }
  }
  const getPreviewUrl = (file: File | null) => {
    return file ? URL.createObjectURL(file) : undefined
  }
  const attachment = watch("attachment") as FileList | null
  const removeImage = (fileToRemove: File) => {
    if (attachment) {
      const updatedAttachment = Array.from(attachment).filter(
        (file) => file !== fileToRemove,
      )
      const dataTransfer = new DataTransfer()
      updatedAttachment.forEach((file) => dataTransfer.items.add(file))
      if (dataTransfer.files !== null) {
        setValue("attachment", dataTransfer.files as any)
      } else {
        setValue("attachment", null)
      }
    }
  }
  return (
    <div className="overflow-auto" style={{ maxHeight: "calc(100vh - 200px)" }}>
      {showCompleted ? (
        <div className="flex flex-col items-center justify-center h-full my-3 fade-in">
          <img src={tick} />
          <div className="flex flex-col items-center gap-8">
            <p className="font-medium text-2xl text-center">
              Post Uploaded
            </p>
            <Button
              name="Cancel"
              customClass="bg-white border-grey-1 px-10"
              customTextClass="!text-header"
              onClick={() => {
                onClose()
                fetchAllGoals(dispatch)
              }}
            />
          </div>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-6 justify-center py-5 xs:p-5"
        >
          <InputField
            required
            type="text"
            name="post"
            control={control}
            hasError={!!errors?.post}
            multiline={true}
            rows={4}
            hasBorder={false}
            width="w-full"
            placeholder="What's Your Update about Goal?"
            customClass="!text-black !override-autofill"
          />
          <div className="grid grid-cols-2 gap-4">
            {attachment &&
              Array.from(attachment).map((file) => (
                <div className="relative">
                  <img
                    key={file.name}
                    src={getPreviewUrl(file)}
                    alt="Preview"
                    height={200}
                    width={200}
                    className="rounded-md"
                  />
                  <div className="absolute top-0 right-5">
                    <IconButton onClick={() => removeImage(file)}>
                      <img src={close} />
                    </IconButton>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex items-center gap-1 pl-2">
            <label htmlFor="attachment">
              <input
                {...register("attachment")}
                id="attachment"
                type="file"
                className="hidden"
                multiple
              />
              <img src={postImage} className="cursor-pointer" />
            </label>
          </div>
          <div className="w-full">
            <div className="flex items-center md:float-right justify-center gap-2 md:gap-4">
              <Button
                name="Cancel"
                type="button"
                onClick={onClose}
                customClass="!px-10 border border-grey-1 bg-white"
                customTextClass="!text-grey-1"
              />
              <Button
                name="Post"
                type="submit"
                loading={isSubmitting}
                customClass={`!px-14 ${
                  isSubmitting
                    ? "border-primary text-primary bg-white font-semibold text-base"
                    : ""
                } `}
              />
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

export default PostForm
