import logo from "@/assets/images/goalLogo.svg"
import userImg from "@/assets/images/noUser.svg"
import { useEffect, useState } from "react"
import CustomMenu from "@/components/UI/Menu"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { authenticate } from "@/slice/auth"
import { RootState } from "@/app/store"
import notification from "@/assets/images/notification.svg"
import noNotifications from "@/assets/images/noNotifications.svg"
import { toggleNotifications } from "@/slice/notifications"
import Notifications from "@/components/Notifications"
import SearchUsers from "@/components/SearchUsers"
import { NavLinkType } from "@/types/navbar"
import { fetchNotifications, navLinks } from "@/utils/navbar"
import profile from "@/assets/images/profile.svg"
import logoutSVG from "@/assets/images/logout.svg"
import CustomPopover from "@/components/UI/Popover"
import { NotificationsType } from "@/types/notification"
import Toast from "@/components/UI/Toast"

const Navbar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    user: { image, first_name, last_name },
    notifications: { isOpen: notificationIsOpen },
    toast: { toastInfo },
  } = useSelector((state: RootState) => state)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(
    null,
  )
  const [notificationsAnchorEl, setNotificationsAnchorEl] =
    useState<null | HTMLElement>(null)
  const [selectedOption, setSelectedOption] = useState<null | string>(null)
  const [isNewNotification, setIsNewNotification] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<NotificationsType>({
    count: 0,
    next: null,
    previous: null,
    results: [],
  })
  const menuItems = ["edit profile", "settings", "goal history", "log out"]
  useEffect(() => {
    if (selectedOption === "log out") {
      window.localStorage.clear()
      dispatch(
        authenticate({
          isAuthenticated: false,
          accessToken: "",
          refreshToken: "",
        }),
      )
      navigate("/")
      window.location.reload()
    }
    if (selectedOption === "goal history") {
      navigate("/goals")
    }
  }, [selectedOption])
  const logout = () => {
    window.localStorage.clear()
    dispatch(
      authenticate({
        isAuthenticated: false,
        accessToken: "",
        refreshToken: "",
      }),
    )
    navigate("/")
    window.location.reload()
  }
  const fetchAllNotifications = (pageNumber?: string | null | undefined) =>
    fetchNotifications(
      dispatch,
      setNotifications,
      setIsNewNotification,
      pageNumber,
    )
  useEffect(() => {
    fetchAllNotifications()
  }, [])
  const wrapInLink = (wrapLink: boolean, navLink: NavLinkType) => {
    const children = (
      <div
        className="flex items-center gap-2"
        onClick={() => !wrapLink && logout()}
      >
        <img
          src={
            location.pathname === navLink.path
              ? navLink.activeIcon
              : navLink.inactiveIcon
          }
        />
        <p
          className={`text-base text-grey-1 font-semibold ${
            location.pathname === navLink.path && "text-primary"
          }`}
        >
          {navLink.name}
        </p>
      </div>
    )
    if (wrapLink) {
      return (
        <Link to={navLink.path} key={navLink.name}>
          {children}
        </Link>
      )
    } else {
      return children
    }
  }
  const ProfileMenuItems = () => (
    <div className="flex flex-col cursor-pointer px-3">
      <div
        className="flex items-center gap-2 p-2 hover:bg-grey-50 rounded"
        onClick={() => {
          navigate("/profile")
          setProfileAnchorEl(null)
        }}
      >
        <img src={profile} />
        <p className="text-sm text-normal">View Profile</p>
      </div>
      <div
        className="flex items-center gap-2 p-2 hover:bg-grey-50 rounded"
        onClick={() => logout()}
      >
        <img src={logoutSVG} />
        <p className="text-sm text-normal text-report">Log Out</p>
      </div>
    </div>
  )

  return (
    <>
      <Toast
        isOpen={toastInfo?.isOpen ?? false}
        onClose={toastInfo?.onClose ?? (() => {})}
        message={toastInfo?.message ?? ""}
        type={toastInfo?.type ?? "error"}
      />
      <CustomMenu
        isOpen={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        items={menuItems}
        anchorEl={anchorEl}
        setSelectedOption={setSelectedOption}
      />
      <CustomMenu
        isOpen={!!profileAnchorEl}
        onClose={() => setProfileAnchorEl(null)}
        items={<ProfileMenuItems />}
        anchorEl={profileAnchorEl}
        setSelectedOption={setSelectedOption}
      />
      <CustomPopover
        anchorEl={notificationsAnchorEl}
        isOpen={!!notificationsAnchorEl}
        onClose={() => setNotificationsAnchorEl(null)}
        customClass="md:w-96 lg:w-98"
      >
        <Notifications
          notifications={notifications}
          onClose={() => setNotificationsAnchorEl(null)}
          fetchAllNotifications={fetchAllNotifications}
        />
      </CustomPopover>
      <div className="bg-white w-full flex items-center border-b justify-between md:justify-around border-b-grey-2 p-5 h-[12%] gap-2 md:gap-0">
        <div className="lg:hidden" />
        <img src={logo} height={60} width={60} />
        <div className="hidden lg:flex items-center gap-5">
          {navLinks.slice(0, 3).map((navLink) => wrapInLink(true, navLink))}
        </div>
        {location.pathname === "/myNetwork" && <SearchUsers />}
        <div className="flex gap-3 items-center">
          <img
            src={!isNewNotification ? noNotifications : notification}
            height={30}
            width={30}
            className="rounded-full cursor-pointer"
            onClick={(e) => {
              setNotificationsAnchorEl(e.currentTarget)
              dispatch(toggleNotifications(!notificationIsOpen))
            }}
          />
          <div
            className="lg:flex hidden items-center gap-3 cursor-pointer"
            onClick={(e) => setProfileAnchorEl(e.currentTarget)}
          >
            <div className="w-7 h-7">
              <img
                src={image ? image : userImg}
                className="h-full w-full rounded-full"
                alt="user"
              />
            </div>
            <p className="text-sm font-normal">
              {first_name} {last_name}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
