import PostCard from "@/components/Cards/Post"
import cover from "@/assets/images/cover.svg"
import Button from "@/components/UI/Button"
import myGoalsInactive from "@/assets/images/myGoalsInactive.svg"
import cup from "@/assets/images/cup.svg"
import { useEffect, useState } from "react"
import { getRequest, postRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@/app/store"
import Loading from "@/components/UI/Loading"
import { updateLayout } from "@/slice/layout"
import { GoalType } from "@/types/goal"
import GoalCard from "@/components/Cards/Goal"
import CustomMenu from "@/components/UI/Menu"
import copyUrl from "@/assets/images/copyUrl.svg"
import report from "@/assets/images/report.svg"
import {
  blockUser,
  checkRequestStatus,
  findFriend,
  sendFriendRequest,
  unfriend,
} from "@/utils/friend"
import userImg from "@/assets/images/noUser.svg"
import { ToastType } from "@/types/toast"
import { fetchFriendList, updateToastState } from "@/utils/index"
import unfollow from "@/assets/images/unfollow.svg"

interface UserData {
  image: string
  bio: string
  completed_goals: number
  first_name: string
  last_name: string
  name: string
  public_goals: number
  uuid: string
  goals?: GoalType[] | []
  cover_image: string
}

const UserProfileScreen = () => {
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    loading: {
      friendProfile: friendProfileLoading,
      friendRequestSend: friendRequestSendLoading,
      unfriend: unfriendLoading,
    },
    friend: { friendList },
  } = useSelector((state: RootState) => state)
  const [data, setData] = useState<UserData>({
    image: cover,
    bio: "",
    completed_goals: 0,
    first_name: "",
    last_name: "",
    name: "",
    public_goals: 0,
    uuid: "",
    cover_image: "",
    goals: [
      {
        id: "",
        progress: 0,
        date_created: 0,
        date_updated: 0,
        title: "",
        description: "",
        start_date: "",
        target_date: "",
        uuid: "",
        current_status: "0",
        is_private: false,
        receive_reminder: false,
      },
    ],
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedOption, setSelectedOption] = useState<null | string>(null)
  const [requestAlreadySent, setRequestAlreadySent] = useState<boolean>(false)

  const fetchProfileData = async () => {
    try {
      const response = await getRequest(
        `${getRoute("friendProfile")}${uuid}`,
        {},
        dispatch,
        "friendProfile",
      )
      setData(response as UserData)
    } catch (err) {}
  }

  const copyToClipboard = async () => {
    try {
      const url = window.location.href
      await navigator.clipboard.writeText(url)
    } catch (err) {}
  }
  useEffect(() => {
    if (selectedOption === "copyUrl") {
      copyToClipboard()
    }
    if (selectedOption === "report") {
      blockUser(dispatch, data.uuid, () => {
        fetchProfileData()
        setSelectedOption(null)
        navigate("/goals")
      })
    }
  }, [selectedOption])
  useEffect(() => {
    fetchProfileData()
    if (uuid) {
      checkRequestStatus(uuid, setRequestAlreadySent)
    }
  }, [uuid])
  useEffect(() => {
    if (friendProfileLoading) {
      dispatch(
        updateLayout({
          leftColumnWidthClass: "w-full",
          rightColumnChildren: <></>,
        }),
      )
    } else {
      dispatch(
        updateLayout({
          leftColumnWidthClass: "w-full lg:w-auto",
          rightColumnChildren: <></>,
        }),
      )
    }
  }, [friendProfileLoading])

  const menuItems = [
    {
      text: <p className="text-sm font-normal">Copy Link to Profile</p>,
      icon: <img src={copyUrl} />,
      identifier: "copyUrl",
    },
    {
      text: <p className="text-report text-sm font-normal">Block</p>,
      icon: <img src={report} />,
      identifier: "report",
    },
  ]
  return friendProfileLoading ? (
    <div className="w-full">
      <Loading />
    </div>
  ) : (
    <>
      <CustomMenu
        anchorEl={anchorEl}
        isOpen={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        items={menuItems}
        setSelectedOption={setSelectedOption}
      />
      <PostCard justify="start" customClass="overflow-y-scroll scrollbar-hide">
        <div className="w-full h-40">
          <img
            src={data.cover_image ? data.cover_image : cover}
            className="h-full w-full"
          />
        </div>
        <div className="flex flex-col px-5 gap-2 -mt-5">
          <div className="w-20 h-20">
            <img
              src={data.image ? data.image : userImg}
              className="h-full w-full rounded-full"
            />
          </div>
          <p className="text-lg font-semibold">{data.name}</p>
          <p className="text-sm font-normal text-grey-1">{data.bio}</p>
          <div className="flex items-center gap-4">
            <div
              className={`flex items-center gap-2 ${
                findFriend(friendList, data.uuid).length > 0 &&
                "bg-unfollow rounded-50px px-5"
              }`}
            >
              {findFriend(friendList, data.uuid).length > 0 && (
                <img src={unfollow} />
              )}
              <Button
                name={
                  requestAlreadySent
                    ? "Pending"
                    : findFriend(friendList, data.uuid).length > 0
                    ? "Unfollow"
                    : "Follow"
                }
                customClass={`${
                  requestAlreadySent
                    ? "bg-grey-200 !px-5 cursor-default"
                    : findFriend(friendList, data.uuid).length > 0
                    ? "bg-unfollow !px-0"
                    : "!px-7"
                } !p-3 border-none`}
                onClick={() => {
                  if (findFriend(friendList, data.uuid).length === 0) {
                    sendFriendRequest(data.uuid, dispatch)
                    fetchFriendList(dispatch)
                    checkRequestStatus(data.uuid, setRequestAlreadySent)
                  } else {
                    unfriend(data.uuid, dispatch)
                  }
                }}
                loading={
                  findFriend(friendList, data.uuid).length === 0
                    ? friendRequestSendLoading
                    : unfriendLoading
                }
                loaderColor="white"
              />
            </div>
            <div onClick={(e) => setAnchorEl(e.currentTarget)}>
              <Button
                name="More"
                customClass="!py-2.5 !px-7 bg-white border-grey-1"
                customTextClass="!text-grey-1"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 pb-5">
          <div className="my-5 px-5 gap-4 flex flex-col">
            <div className="flex gap-8">
              <div className="flex flex-col">
                <div className="flex gap-1">
                  <img src={myGoalsInactive} />
                  <p className="text-base font-medium">{data.public_goals}</p>
                </div>
                <p className="text-xs font-normal">Total Created Goals</p>
              </div>
              <div className="flex flex-col">
                <div className="flex gap-1">
                  <img src={cup} />
                  <p className="text-base font-medium">
                    {data.completed_goals}
                  </p>
                </div>
                <p className="text-xs font-normal">Total Achieved Goals</p>
              </div>
            </div>
            <p className="test-sm font-semibold">Goals</p>
          </div>
          {data.goals && data.goals.length > 0 ? (
            data?.goals?.map((goal) => (
              <GoalCard goal={goal} key={goal.uuid} showButtons={false} />
            ))
          ) : (
            <div className="flex items-center justify-center">
              <p className="text-sm font-normal">No goals to show</p>
            </div>
          )}
        </div>
      </PostCard>
    </>
  )
}

export default UserProfileScreen
