import { postRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import { RootState } from "@/app/store"
import Button from "@/components/UI/Button"
import InputField from "@/components/UI/InputField"
import Toast from "@/components/UI/Toast"
import { ToastInfoType, ToastType } from "@/types/toast"
import { validatePassword } from "@/utils/auth"
import { handleError, updateToastState } from "@/utils/index"
import { useState } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

type Inputs = {
  firstName: string
  lastName: string
  email: string
  password1: string
  password2: string
}

const Form1 = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    loading: { register: registerLoading },
  } = useSelector((state: RootState) => state)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password1: "",
      password2: "",
      phoneNumber: "",
    },
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const password1Error = validatePassword(data.password1)
    if (password1Error) {
      updateToastState(dispatch, password1Error, ToastType.Error)
      return
    }
    if (data.password1 !== data.password2) {
      updateToastState(dispatch, "Passwords do not match", ToastType.Error)
      return
    }
    try {
      await postRequest(
        getRoute("register"),
        {
          first_name: data.firstName,
          last_name: data.lastName,
          password1: data.password1,
          password2: data.password2,
          email: data.email,
        },
        dispatch,
        "register",
      )
      updateToastState(dispatch, "Registration successful", ToastType.Success)
      navigate("/login")
    } catch (err: any) {
      const error = handleError(err)
      let errorMessage = "Failed to register"
      if (error.email) {
        errorMessage = error.email[0]
      } else if (error.non_field_errors) {
        errorMessage = error.non_field_errors[0]
      }
      updateToastState(dispatch, errorMessage, ToastType.Error)
    }
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-center gap-6"
    >
      <InputField
        control={control}
        name="firstName"
        placeholder="First Name"
        type="text"
        hasError={!!errors?.firstName}
        required={true}
        customClass="!text-black !override-autofill"
      />
      <InputField
        control={control}
        name="lastName"
        placeholder="Last Name"
        type="text"
        hasError={!!errors?.lastName}
        required={true}
        customClass="!text-black !override-autofill"
      />
      <InputField
        control={control}
        name="email"
        placeholder="Email"
        type="text"
        hasError={!!errors?.email}
        required={true}
        customClass="!text-black !override-autofill"
      />
      <InputField
        control={control}
        name="password1"
        placeholder="Password"
        type="password"
        hasError={!!errors?.password1}
        required={true}
        customClass="!text-black !override-autofill"
        hasIcon
      />
      <InputField
        control={control}
        name="password2"
        placeholder="Confirm Password"
        type="password"
        hasError={!!errors?.password2}
        required={true}
        customClass="!text-black !override-autofill"
        hasIcon
      />
      <Button
        name="Create account"
        type="submit"
        fullWidth
        loading={registerLoading}
        loaderColor="white"
      />
    </form>
  )
}

export default Form1
