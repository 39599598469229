import { Menu, MenuItem } from "@mui/material"
import { ReactNode } from "react"

interface MenuItem {
  icon?: ReactNode
  text?: ReactNode
  identifier?: string
}

interface CustomMenuProps {
  isOpen: boolean
  onClose: () => void
  anchorEl: HTMLElement | null
  items: ReactNode | MenuItem[] | string[]
  setSelectedOption?: (item: string) => void
  width?: number
}

const CustomMenu = ({
  isOpen,
  onClose,
  anchorEl,
  items,
  setSelectedOption,
  width = 265.2,
}: CustomMenuProps) => {
  const handleMenuItemClick = (item: string) => {
    onClose()
    if (setSelectedOption) {
      setSelectedOption(item)
    }
  }
  const style = { width, borderRadius: "8px" }
  return (
    <Menu
      PaperProps={{
        style,
      }}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
    >
      {Array.isArray(items) ? (
        items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              if (typeof item === "string") {
                handleMenuItemClick(item)
              } else if (item.identifier) {
                handleMenuItemClick(item.identifier)
              }
            }}
            className="capitalize !py-3 !text-sm !font-normal !text-grey-1"
          >
            {typeof item === "string" ? (
              item
            ) : (
              <div className="flex items-center gap-2">
                {item.icon} {item.text}
              </div>
            )}
          </MenuItem>
        ))
      ) : (
        <div className="w-full">{items}</div>
      )}
    </Menu>
  )
}
export default CustomMenu
