import AuthHeader from "@/components/AuthHeader"
import LoginForm from "@/components/Forms/Login"
import AuthLayout from "@/components/Layout/Auth"
import { Link } from "react-router-dom"
import AuthFooter from "@/components/AuthFooter"

const LoginScreen = () => {
  return (
    <AuthLayout>
      <div className="flex flex-col justify-center gap-19 px-5 py-2 md:p-10 lg:p-16">
        <AuthHeader
          greetingText="Welcome back"
          subGreetingText="Please enter your details to sign in."
        />
        <LoginForm />
        {/* <AuthFooter action="Continue" /> */}
      </div>
      <p className="font-normal text-sm text-center">
        Don't have an account?
        <Link to="/register">
          <span className="font-medium text-sm ml-2">Sign Up</span>
        </Link>
      </p>
    </AuthLayout>
  )
}

export default LoginScreen
