import Button from "@/components/UI/Button"
import InputField from "@/components/UI/InputField"
import search from "@/assets/images/search.svg"
import { SubmitHandler, useForm } from "react-hook-form"
import { postRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import { useDispatch } from "react-redux"
import { Dispatch, SetStateAction, useEffect } from "react"
import { SearchUserType } from "@/types/user"

type Inputs = {
  searchText: string
}

interface SearchFormProps {
  toggleSearchResults: () => void
  setReset: Dispatch<SetStateAction<() => void>>
  setUsersList: Dispatch<SetStateAction<SearchUserType[]>>
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>
}

const SearchForm = ({
  toggleSearchResults,
  setReset,
  setUsersList,
  setAnchorEl,
}: SearchFormProps) => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      searchText: "",
    },
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    toggleSearchResults()
    try {
      const response = await postRequest(
        getRoute("userSearch"),
        {
          user_search: data.searchText,
        },
        dispatch,
        "userSearch",
      )
      setUsersList(response)
    } catch (err) {}
  }

  useEffect(() => {
  setReset(() => reset)
  }, [reset])
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className="flex items-center border border-border-4 rounded-xl relative "
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <InputField
          control={control}
          name="searchText"
          type="text"
          placeholder="Search friends"
          hasError={!!errors?.searchText}
          required={false}
          padding="py-0 px-3"
          customClass="!rounded-none !border-none !shadow-none !text-black !override-autofill"
          wrapperCustomClass="border-none rounded-xl"
        />
        <Button
          icon={<img src={search} />}
          customClass="rounded-xl !p-0"
          type="submit"
          iconWrapperGap="gap-0"
        />
      </div>
    </form>
  )
}

export default SearchForm
