import { postRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import { RootState } from "@/app/store"
import FriendGoalsCard from "@/components/Cards/FriendGoals"
import CardsContainer from "@/components/CardsContainer"
import FriendList from "@/components/FriendList"
import Button from "@/components/UI/Button"
import Loading from "@/components/UI/Loading"
import { updateLayout } from "@/slice/layout"
import { FeedType } from "@/types/feed"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

interface FriendGoal {
  count: number
  next: string
  previous: string
  results: FeedType[] | []
}

const FriendGoalsScreen = () => {
  const dispatch = useDispatch()
  const { feed: feedLoading } = useSelector((state: RootState) => state.loading)
  const [feedList, setFeedList] = useState<FriendGoal>({
    count: 0,
    next: "",
    previous: "",
    results: [],
  })

  const fetchFeed = async (pageNumber?: string) => {
    await postRequest(
      `${getRoute("feed")}${pageNumber ? `?page=${pageNumber}` : ""}`,
      {},
      dispatch,
      "feed",
    )
      .then((res) => {
        if (pageNumber) {
          setFeedList((prev) => ({
            count: res.count,
            next: res.next,
            previous: res.previous,
            results: prev.results.concat(res.results),
          }))
        } else {
          setFeedList(res)
        }
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    fetchFeed()
  }, [])

  useEffect(() => {
    if (feedLoading) {
      dispatch(
        updateLayout({
          leftColumnWidthClass: "w-full",
          rightColumnChildren: <></>,
        }),
      )
    } else {
      dispatch(
        updateLayout({
          leftColumnWidthClass: "",
          rightColumnChildren: <FriendList />,
        }),
      )
    }
  }, [feedLoading])
  const fetchNext = () => {
    fetchFeed(feedList.next && feedList.next?.split("page=")[1])
  }
  return (
    <div className="w-full overflow-y-scroll scrollbar-hide">
      {feedLoading ? (
        <Loading />
      ) : feedList.results.length > 0 ? (
        <CardsContainer>
          {feedList.results.map((feed) => (
            <FriendGoalsCard key={feed.uuid} friendGoal={feed} />
          ))}
          {feedList.next && (
            <Button
              name="Load More"
              iconWrapperGap="gap-0"
              isIconAfter
              customTextClass="!text-primary !font-semibold text-sm"
              customClass="mb-5 bg-transparent border-none"
              onClick={fetchNext}
            />
          )}
        </CardsContainer>
      ) : (
        <div className="h-full justify-center items-center flex w-full lg:max-w-3xl lg:w-[596px]">
          <p>Nothing to show</p>
        </div>
      )}
    </div>
  )
}

export default FriendGoalsScreen
