import logo from "@/assets/images/goalLogo.svg"
import CircleIcon from "@mui/icons-material/Circle"

const PrivacyPolicyScreen = () => {
  return (
    <div className="h-screen w-screen">
      <div className="w-full h-72 bg-white flex items-center justify-center">
        <div className="w-11/12 md:w-4/12">
          <img src={logo} />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center h-full">
        <div className="flex flex-col items-start w-11/12 md:w-4/12 py-5">
          <p className="text-base font-semibold">Privacy Policy</p>
        </div>
        <div className="h-full w-full bg-white flex flex-col items-center gap-4 py-5 overflow-y-scroll scrollbar-hide">
          <div className="flex flex-col items-start w-11/12 md:w-4/12 gap-4">
            <p className="text-base font-semibold">Your Privacy Matters</p>
            <p className="text-normal text-sm">
              Goal'as mission is to connect the world's professionals to allow
              them to be more productive and successful. Central to this mission
              is our commitment to be transparent about the data we collect
              about you, how it is used and with whom it is shared.{" "}
            </p>
            <p className="text-normal text-sm">
              This Privacy Policy applies when you use our Services (described
              below). We offer our users{" "}
              <span className="underline">choices</span> about the data we
              collect, use and share as described in this Privacy Policy,{" "}
              <span className="underline">Cookie Policy</span>, Settings and our{" "}
              <span className="underline">Help Center</span>.
            </p>
          </div>
          <div className="flex flex-col items-start w-11/12 md:w-4/12 gap-4">
            <p className="text-base font-semibold">Table of Contents</p>
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <CircleIcon className="!text-5" />
                <p className="text-normal text-sm">Data We Collect</p>
              </div>
              <div className="flex items-center gap-2">
                <CircleIcon className="!text-5" />
                <p className="text-normal text-sm">How We Use Your Data</p>
              </div>
              <div className="flex items-center gap-2">
                <CircleIcon className="!text-5" />
                <p className="text-normal text-sm">How We Share Information</p>
              </div>
              <div className="flex items-center gap-2">
                <CircleIcon className="!text-5" />
                <p className="text-normal text-sm">
                  Your Choices and Obligations
                </p>
              </div>
              <div className="flex items-center gap-2">
                <CircleIcon className="!text-5" />
                <p className="text-normal text-sm">
                  Other Important Information
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start w-11/12 md:w-4/12">
            <p className="text-base font-semibold">
              Data Controllers and Contracting Parties
            </p>
            <p className="text-normal text-sm">
              If you are in the “Designated Countries”, LinkedIn Ireland
              Unlimited Company (“LinkedIn Ireland”) will be the controller of
              your personal data provided to, or collected by or for, or
              processed in connection with our Services.
              <br />
              If you are outside of the Designated Countries, LinkedIn
              Corporation will be the controller of your personal data provided
              to, or collected by or for, or processed in connection with, our
              Services. As a Visitor or Member of our Services, the collection,
              use and sharing of your personal data is subject to this Privacy
              Policy and other documents referenced in this Privacy Policy, as
              well as updates.
            </p>
          </div>
          <div className="flex flex-col items-start w-11/12 md:w-4/12 gap-4">
            <p className="text-base font-semibold">Services</p>
            <div className="bg-skyBlue p-3 rounded">
              <p className="text-normal text-sm">
                This Privacy Policy, including our Cookie Policy applies to your
                use of our Services.
              </p>
            </div>
            <p className="text-normal text-sm">
              This Privacy Policy applies to LinkedIn.com, LinkedIn-branded
              apps, LinkedIn Learning and other LinkedIn-related sites, apps,
              communications and services (“Services”), including off-site
              Services, such as our ad services and the “Apply with LinkedIn”
              and “Share with LinkedIn” plugins, but excluding services that
              state that they are offered under a different privacy policy. For
              California residents, additional disclosures required by
              California law may be found in our California Privacy Disclosure.
            </p>
          </div>
          <div className="flex flex-col items-start w-11/12 md:w-4/12 gap-4">
            <p className="text-base font-semibold">Change</p>
            <p className="text-normal text-sm">
              Changes to the Privacy Policy apply to your use of our Services
              after the “effective date.” <br />
              LinkedIn (“we” or “us”) can modify this Privacy Policy, and if we
              make material changes to it, we will provide notice through our
              Services, or by other means, to provide you the opportunity to
              review the changes before they become effective. If you object to
              any changes, you may close your account.
              <br />
              You acknowledge that your continued use of our Services after we
              publish or send a notice about our changes to this Privacy Policy
              means that the collection, use and sharing of your personal data
              is subject to the updated Privacy Policy, as of its effective
              date.
            </p>
          </div>
          <div className="flex flex-col items-start w-11/12 md:w-4/12 gap-4">
            <p className="text-base font-semibold">Data We Collect</p>
            <p className="text-normal text-sm">
              Data You Provide To Us
              <br /> You provide data to create an account with us
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicyScreen
