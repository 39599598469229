import { RootState } from "@/app/store"
import Button from "@/components/UI/Button"
import InputField from "@/components/UI/InputField"
import { extractFirstAndLastName } from "@/utils/friend"
import { updateProfile } from "@/utils/profile"
import { SubmitHandler, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

type Inputs = {
  name: string
  bio: string
}

interface GeneralProps {
  toggleEditMode: () => void
}

const General = ({ toggleEditMode }: GeneralProps) => {
  const dispatch = useDispatch()
  const {
    user: { first_name, last_name, bio },
    loading: { updateProfile: updateProfileLoading },
  } = useSelector((state: RootState) => state)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: `${first_name} ${last_name}`,
      bio: bio,
    },
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const [firstName, lastName] = extractFirstAndLastName(data.name)
    const dataToUpdate = {
      bio: data.bio,
      first_name: firstName,
      last_name: lastName,
    }
    updateProfile(dataToUpdate, dispatch, toggleEditMode, true)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
      <InputField
        control={control}
        name="name"
        placeholder="Name"
        type="text"
        hasError={!!errors?.name}
        required={true}
        customClass="!text-black"
      />
      <InputField
        control={control}
        name="bio"
        placeholder="Bio"
        type="text"
        hasError={!!errors?.bio}
        required={true}
        multiline
        customClass="!text-black w-full"
      />
      <div className="w-full">
        <div className="float-right flex items-center gap-4">
          <Button
            name="Cancel"
            type="button"
            onClick={toggleEditMode}
            customClass="!px-10 border border-grey-1 bg-white"
            customTextClass="!text-grey-1"
          />
          <Button
            name="Save changes"
            type="submit"
            loading={updateProfileLoading}
            customClass="!px-4"
            loaderColor="white"
          />
        </div>
      </div>
    </form>
  )
}

export default General
