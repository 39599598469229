import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface AuthState {
  isAuthenticated: boolean
  accessToken: string
  refreshToken: string
}

const initialState: AuthState = {
  isAuthenticated: false,
  accessToken: "",
  refreshToken: "",
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticate: (state, action: PayloadAction<AuthState>) => {
      state.isAuthenticated = action.payload.isAuthenticated
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
    },
  },
})

export const { authenticate } = authSlice.actions
export default authSlice.reducer
