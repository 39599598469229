type ApiRoutes = {
  login: string
  goals: string
  goalPost: string
  goalPostList: string
  feed: string
  goalPostLike: string
  goalPostComment: string
  profile: string
  notification: string
  commentList: string
  friendList: string
  friendRequestList: string
  friendRequestRespond: string
  userSearch: string
  friendRequestSend: string
  readNotification: string
  goalMarkAsDone: string
  friendProfile: string
  register: string
  requestStatus: string
  updateProfile: string
  resetPassword: string
  friendGoalList: string
  friendGoalPostList: string
  readAllNotifications: string
  blockUser: string
  unfriend: string
}

const apiRoutes: ApiRoutes = {
  login: "api/auth/token/",
  goals: "api/goals/",
  goalPost: "api/goals/goal-posts/",
  goalPostList: "api/goals/goal-posts-list/",
  feed: "api/feed/",
  goalPostLike: "api/goals/goal-post-like/",
  goalPostComment: "api/goals/goal-post-comment/",
  profile: "api/users/profile/",
  notification: "api/notifications/",
  commentList: "api/goals/goal-post-comment-list/",
  friendList: "api/users/friend-list/",
  friendRequestList: "api/users/friend-request-list/",
  friendRequestRespond: "api/users/friend-request-respond/",
  userSearch: "api/users/user-search/",
  friendRequestSend: "api/users/friend-request-send/",
  readNotification: "api/notifications/read/",
  goalMarkAsDone: "api/goals/",
  friendProfile: "api/users/friend-profile/",
  register: "api/auth/registration/",
  requestStatus: "api/users/friend-request-check/",
  updateProfile: "api/users/profile-update/",
  resetPassword: "api/auth/auhtenticated-password-reset/",
  friendGoalList: "api/goals/friend-goal/",
  friendGoalPostList: "api/goals/friend-goal-posts-list/",
  readAllNotifications: "api/notifications/read-all/",
  blockUser: "api/users/block/",
  unfriend: "api/users/unfriend-user/",
}

export const getRoute = (routeKey: keyof ApiRoutes) => apiRoutes[routeKey]
