import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ReactNode } from "react"

interface Screen {
  rightColumnChildren: ReactNode | null
  leftColumnWidthClass: string
}

export interface LayoutState {
  screen: Screen
}

const initialState: LayoutState = {
  screen: { rightColumnChildren: null, leftColumnWidthClass: "w-full" },
}

export const authSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    updateLayout: (state, action: PayloadAction<Screen>) => {
      state.screen = action.payload
    },
  },
})

export const { updateLayout } = authSlice.actions
export default authSlice.reducer
