import { RootState } from "@/app/store"
import { useSelector } from "react-redux"
import Button from "@/components/UI/Button"
import PostCard from "@/components/Cards/Post"
import { useNavigate } from "react-router-dom"
import userImg from "@/assets/images/noUser.svg"

const FriendList = () => {
  const navigate = useNavigate()
  const {
    friend: { friendList },
  } = useSelector((state: RootState) => state)
  return (
    <PostCard widthClass="w-90" customHeightClass="h-card" justify="start">
      <div className="p-5">
        <p className="font-semibold text-sm">Your Friends list</p>
        <div className="flex flex-col gap-4 mt-5">
          {friendList.length > 0 ? (
            friendList.map((friend) => (
              <div
                key={friend.uuid}
                className="flex items-center gap-7 justify-between"
              >
                <div className="flex items-start gap-2">
                  <div className="w-10 h-10">
                    <img
                      src={friend.image ? friend.image : userImg}
                      className="w-full h-full rounded-full"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-sm font-medium break-all">
                      {friend.name}
                    </p>
                    <p className="text-xs font-semibold text-grey-1">
                      {friend.total_goals} Goals
                    </p>
                  </div>
                </div>
                <Button
                  name="View Goals"
                  customClass="bg-grey-2 !rounded-full w-6/12"
                  customTextClass="!text-grey-1 text-xs font-semibold"
                  onClick={() => navigate(`/user/${friend.uuid}`)}
                />
              </div>
            ))
          ) : (
            <div className="flex justify-center">
              <p className="text-sm font-normal">No friends yet</p>
            </div>
          )}
        </div>
      </div>
    </PostCard>
  )
}

export default FriendList
