import { authenticate } from "@/slice/auth"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import Button from "@/components/UI/Button"
import InputField from "@/components/UI/InputField"
import { useForm, SubmitHandler } from "react-hook-form"
import { postRequest, setAuthToken } from "@/api/index"
import { getRoute } from "@/api/routes"
import Toast from "@/components/UI/Toast"
import { handleError, updateToastState } from "@/utils/index"
import SecureLS from "secure-ls"
import { ToastType } from "@/types/toast"

type Inputs = {
  email: string
  password: string
  rememberMe: boolean
}

const LoginForm = () => {
  const ls = new SecureLS()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
  })
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const response = await postRequest(getRoute("login"), {
        email: data.email,
        password: data.password,
      })
      dispatch(
        authenticate({
          accessToken: response.access,
          refreshToken: response.refresh,
          isAuthenticated: true,
        }),
      )

      ls.set("accessToken", response.access)
      ls.set("refreshToken", response.refresh)
      setAuthToken(response.access)
      navigate("/goals")
    } catch (err) {
      const error = handleError(err)
      updateToastState(dispatch, error?.non_field_errors[0], ToastType.Error)
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-center gap-6 w-full"
    >
      <InputField
        control={control}
        name="email"
        placeholder="Email address"
        type="text"
        hasError={!!errors?.email}
        required={true}
        customClass="!text-black !override-autofill"
      />
      <InputField
        control={control}
        name="password"
        placeholder="Password"
        type="password"
        hasError={!!errors?.password}
        required={true}
        customClass="!text-black !override-autofill"
        hasIcon
      />
      <div className="flex justify-between w-full items-center">
        <div className="flex items-center gap-2">
          <input type="checkbox" {...register("rememberMe")} />
          <p className="text-sm text-gray-600 hover:text-gray-900">
            Remember me
          </p>
        </div>
        <p className="text-sm text-gray-600 hover:text-gray-900 cursor-pointer">
          Forgot password?
        </p>
      </div>
      <Button
        name="Login"
        type="submit"
        loading={isSubmitting}
        fullWidth
        loaderColor="white"
        customClass="rounded-lg"
      />
    </form>
  )
}

export default LoginForm
