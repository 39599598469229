import Button from "@/components/UI/Button"
import CustomSwitch from "@/components/UI/Switch"
import { Dispatch, SetStateAction } from "react"

interface NotificationsProps {
  setSelectedOption: Dispatch<SetStateAction<string>>
}

const Notifications = ({ setSelectedOption }: NotificationsProps) => {
  return (
    <div className="">
      <div className="border-b border-border-3 p-2 pl-0 w-full">
        <p className="text-grey-1 font-semibold text-lg">Notifications</p>
      </div>
      <div className="py-5 flex flex-col gap-6">
        <p className="text-sm font-medium">Goals</p>
        <div className="flex justify-between">
          <p className="text-body text-xs font-normal">
            Notify me when people I follow post a goal
          </p>
          <CustomSwitch />
        </div>
        <div className="flex justify-between">
          <p className="text-body text-xs font-normal">
            Notify me when people I follow update their goals
          </p>
          <CustomSwitch />
        </div>
        <div className="flex justify-between">
          <p className="text-body text-xs font-normal">
            Notify me when people I follow make a post for their goal
          </p>
          <CustomSwitch />
        </div>
        <p className="text-sm font-medium">Comments</p>
        <div className="flex justify-between">
          <p className="text-body text-xs font-normal">
            Notify me when someone comments on my goal posts
          </p>
          <CustomSwitch />
        </div>
        <div className="flex justify-between">
          <p className="text-body text-xs font-normal">
            Notify me when someone comments on my follower's goal post
          </p>
          <CustomSwitch />
        </div>
        <div className="flex justify-between">
          <p className="text-body text-xs font-normal">
            Notify me of comments only from people I follow
          </p>
          <CustomSwitch />
        </div>
        <p className="text-sm font-medium">Friend requests</p>
        <div className="flex justify-between">
          <p className="text-body text-xs font-normal">
            Notify me when someone sends a friend request
          </p>
          <CustomSwitch />
        </div>
        <div className="flex justify-between">
          <p className="text-body text-xs font-normal">
            Notify me when someone accepts my friend request
          </p>
          <CustomSwitch />
        </div>
      </div>
      <div className="border-b border-border-3 p-2 pl-0 w-full">
        <p className="text-grey-1 font-semibold text-lg">Notifications mode</p>
      </div>
      <div className="py-5 flex flex-col gap-6">
        <div className="flex justify-between">
          <p className="text-body text-xs font-normal">
            Send me notifications via the app
          </p>
          <CustomSwitch />
        </div>
        <div className="flex justify-between">
          <p className="text-body text-xs font-normal">
            Send me notifications via email
          </p>
          <CustomSwitch />
        </div>
        <div className="flex justify-between">
          <p className="text-body text-xs font-normal">
            Send me notifications via email and the app
          </p>
          <CustomSwitch />
        </div>
      </div>
      <div className="w-full">
        <Button
          customClass="bg-white border-grey-1 px-8 float-right"
          customTextClass="!text-grey-1"
          name="Cancel"
          onClick={() => setSelectedOption("")}
        />
      </div>
    </div>
  )
}

export default Notifications
