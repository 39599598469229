import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface ProfileState {
  selectedSetting: string
}

const initialState: ProfileState = {
  selectedSetting: "",
}

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateSelectedProfileSetting: (state, action: PayloadAction<string>) => {
      state.selectedSetting = action.payload
    },
  },
})

export const { updateSelectedProfileSetting } = profileSlice.actions
export default profileSlice.reducer
