import Navbar from "@/components/Navbar"
import { ReactNode } from "react"
import { Link, useLocation } from "react-router-dom"
import { navLinks } from "@/utils/navbar"
import { NavLinkType } from "@/types/navbar"
import { useSelector } from "react-redux"
import { RootState } from "@/app/store"
import userImg from "@/assets/images/noUser.svg"
interface LayoutProps {
  rightColumnChildren?: ReactNode
  leftColumnChildren?: ReactNode
  leftColumnWidthClass?: string
}

const Layout = ({
  leftColumnChildren,
  rightColumnChildren,
  leftColumnWidthClass,
}: LayoutProps) => {
  const location = useLocation()
  const {
    user: { image },
  } = useSelector((state: RootState) => state)
  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen w-screen">
        <Navbar />
        <div className="flex h-[88%] w-full">
          <div className="w-full flex justify-center pb-22 pt-2 lg:pb-0 md:pt-5 md:pb-22 gap-8">
            <div className={`flex gap-8 ${leftColumnWidthClass}`}>
              {leftColumnChildren}
              <div className="hidden lg:flex flex-col">
                {rightColumnChildren}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white fixed bottom-0 p-5 lg:hidden flex justify-center gap-4 items-center">
        {navLinks.map((navLink: NavLinkType) => (
          <Link to={navLink.path} className="w-1/4">
            <div className="flex items-center gap-2 flex-col">
              <div className="w-7 h-7">
                <img
                  src={
                    location.pathname === navLink.path
                      ? navLink.path === "/profile"
                        ? image
                          ? image
                          : userImg
                        : navLink.activeIcon
                      : navLink.path === "/profile"
                      ? image
                        ? image
                        : userImg
                      : navLink.inactiveIcon
                  }
                  className="h-full w-full rounded-full"
                />
              </div>
              <p
                className={`text-[10px] font-semibold text-center ${
                  location.pathname === navLink.path && "text-primary"
                }`}
              >
                {navLink.name}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </>
  )
}

export default Layout
