import { Dispatch, ReactNode, SetStateAction, useState } from "react"
import Icon from "@/components/UI/Icon"
import { IconType } from "@/types/icon"
import Comments from "@/components/Comments"

interface PostCardProps {
  children: ReactNode
  header?: ReactNode
  footer?: ReactNode
  icons?: IconType[]
  uuid?: string
  widthClass?: string
  justify?: string
  customHeightClass?: string
  customClass?: string
  footerCustomClass?: string
  setCommentCount?: Dispatch<SetStateAction<number>>
}

const PostCard = ({
  children,
  header,
  icons,
  footer,
  uuid,
  widthClass = "w-full max-w-md md:max-w-2xl lg:max-w-3xl lg:w-[596px]",
  justify = "center",
  customHeightClass,
  customClass,
  footerCustomClass,
  setCommentCount,
}: PostCardProps) => {
  const [enableCommentForm, setEnableCommentForm] = useState<boolean>(false)
  const toggleCommentForm = () => setEnableCommentForm((prev) => !prev)
  const iconsList =
    icons &&
    icons.map((icon, index) => {
      if (index === 1) {
        return { ...icon, action: toggleCommentForm }
      }
      return icon
    })
  const showComments = enableCommentForm && uuid && setCommentCount
  return (
    <div
      className={`bg-white flex flex-col justify-${justify} ${widthClass} ${customHeightClass} rounded shadow-lg ${customClass} mx-auto`}
    >
      {header}
      {children}
      {(footer || iconsList) && (
        <div
          className={`flex items-center justify-between p-5 gap-2 md:gap-0 ${footerCustomClass}`}
        >
          {iconsList && (
            <div className="flex items-center gap-4">
              {iconsList.map((icon) => (
                <Icon
                  heading={icon.heading}
                  icon={icon.icon}
                  action={icon.action}
                  cursor={icon.cursor}
                />
              ))}
            </div>
          )}
          {footer}
        </div>
      )}
      {showComments && (
        <div className="flex flex-col justify-center">
          <Comments uuid={uuid} setCommentCount={setCommentCount} />
        </div>
      )}
    </div>
  )
}

export default PostCard
