import { getRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import { RootState } from "@/app/store"
import Loading from "@/components/UI/Loading"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FriendRequestType } from "@/types/friendRequest"
import FriendRequestCard from "@/components/Cards/FriendRequest"

const MyNetworkScreen = () => {
  const dispatch = useDispatch()
  const {
    loading: { friendRequestList: friendRequestListLoading },
  } = useSelector((state: RootState) => state)
  const [friendRequestList, setFriendRequestList] = useState<
    FriendRequestType[]
  >([])

  const fetchFriendRequests = async () => {
    await getRequest(
      getRoute("friendRequestList"),
      {},
      dispatch,
      "friendRequestList",
    )
      .then((res) => setFriendRequestList(res))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    fetchFriendRequests()
  }, [])

  return (
    <div className="flex flex-col px-5 pb-5 w-full overflow-y-scroll scrollbar-hide">
      {friendRequestListLoading ? (
        <Loading />
      ) : friendRequestList.length > 0 ? (
        <>
          <p className="font-semibold text-2xl">Pending Invitation</p>
          <div className="flex flex-col mt-5 w-full justify-center">
            <div
              className={`grid w-full gap-8 md:px-10 ${
                friendRequestList.length !== 1&&'lg:grid-cols-2'
              }`}
            >
              {friendRequestList.map((friendRequest: FriendRequestType) => (
                <FriendRequestCard
                  friendRequest={friendRequest}
                  key={friendRequest.id}
                  fetchFriendRequests={fetchFriendRequests}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-full w-full">
          <p className="text-sm font-normal">Nothing to show</p>
        </div>
      )}
    </div>
  )
}

export default MyNetworkScreen
