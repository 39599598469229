import { RootState } from "@/app/store"
import PostCard from "@/components/Cards/Post"
import { updateSelectedProfileSetting } from "@/slice/profile"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import defaultCover from "@/assets/images/cover.svg"
import userImg from "@/assets/images/noUser.svg"
import Button from "@/components/UI/Button"
import myGoalsInactive from "@/assets/images/myGoalsInactive.svg"
import cup from "@/assets/images/cup.svg"
import Password from "@/components/Forms/Settings/Profile/Password"
import logout from "@/assets/images/logout.svg"
import Notifications from "@/components/Forms/Settings/Profile/Notifications"
import General from "@/components/Forms/Settings/Profile/General"
import edit from "@/assets/images/edit.svg"
import { useForm } from "react-hook-form"
import { updateProfile } from "@/utils/profile"
import { useNavigate } from "react-router-dom"

const ProfileScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    user: {
      first_name,
      last_name,
      completed_goals,
      public_goals,
      image,
      bio,
      cover_image,
    },
    profile: { selectedSetting },
  } = useSelector((state: RootState) => state)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<string>(selectedSetting)
  const toggleEditMode = () => setIsEditMode((prev) => !prev)
  const { register, watch } = useForm({
    defaultValues: {
      coverImg: null,
      profileImg: null,
    },
  })
  const options = [
    "Password",
    // "Notifications",
    "Privacy & Policy",
    "Terms & Conditions",
    "Log Out",
  ]
  const selectOption = (option: string) => {
    setSelectedOption(option)
    dispatch(updateSelectedProfileSetting(option))
  }
  useEffect(() => {
    dispatch(updateSelectedProfileSetting(""))
  }, [])
  const handleOptionClick = (index: number, option: string) => {
    if (index === 3) {
      localStorage.clear()
      window.location.reload()
    }
    if (index === 1) {
      navigate("/legal/privacyPolicy")
    } else if (index === 2) {
      navigate("/legal/termsAndConditions")
    } else {
      selectOption(option)
    }
  }
  const getPreviewUrl = (file: File | null) => {
    return file ? URL.createObjectURL(file) : undefined
  }
  const coverImg = watch("coverImg") as FileList | null
  const profileImg = watch("profileImg") as FileList | null
  useEffect(() => {
    if (coverImg) {
      const formData = new FormData()
      Array.from(coverImg).forEach((file) => {
        formData.append("cover_image", file)
      })
      updateProfile(formData, dispatch)
    }
    if (profileImg) {
      const formData = new FormData()
      Array.from(profileImg).forEach((file) => {
        formData.append("image", file)
      })
      updateProfile(formData, dispatch)
    }
  }, [coverImg, profileImg])
  return (
    <PostCard
      justify="start"
      customClass="md:w-[800px] overflow-y-scroll scrollbar-hide"
    >
      <div className="relative h-36 w-full">
        {coverImg ? (
          Array.from(coverImg).map((file) => (
            <img
              src={getPreviewUrl(file)}
              key={file.name}
              className="w-full h-full bg-cover"
            />
          ))
        ) : (
          <img
            src={cover_image ? cover_image : defaultCover}
            className="w-full h-full bg-cover"
          />
        )}
        {isEditMode && (
          <>
            <div className="flex items-center gap-1 pl-2">
              <label htmlFor="coverImg">
                <input
                  {...register("coverImg")}
                  id="coverImg"
                  type="file"
                  className="hidden"
                />
                <div className="absolute right-2 top-2 rounded-full bg-grey-2 p-2 cursor-pointer">
                  <img src={edit} />
                </div>
              </label>
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col px-5 gap-2 -mt-5">
        <div className="w-20 h-20 relative">
          {profileImg ? (
            Array.from(profileImg).map((file) => (
              <img
                src={getPreviewUrl(file)}
                key={file.name}
                className="h-full w-full rounded-full"
              />
            ))
          ) : (
            <img
              src={image ? image : userImg}
              className="h-full w-full rounded-full"
            />
          )}
          {isEditMode && (
            <div className="flex items-center gap-1 pl-2">
              <label htmlFor="profileImg">
                <input
                  {...register("profileImg")}
                  id="profileImg"
                  type="file"
                  className="hidden"
                />
                <div className="absolute -right-2 bottom-2 rounded-full bg-grey-2 p-2 cursor-pointer">
                  <img src={edit} />
                </div>
              </label>
            </div>
          )}
        </div>
        <p className="text-lg font-semibold">
          {first_name} {last_name}
        </p>
        {bio && <p className="text-xs font-normal">{bio}</p>}
      </div>
      {isEditMode ? (
        <div className="mt-5 px-5 mb-7">
          <General toggleEditMode={toggleEditMode} />
        </div>
      ) : !selectedOption ? (
        <div className="flex flex-col px-5 gap-2 mb-7">
          <div>
            <Button
              name="Edit profile"
              customClass="mt-3 !p-3"
              customTextClass="!text-xs"
              onClick={toggleEditMode}
            />
          </div>
          <div className="flex items-center gap-8 my-5">
            <div className="flex flex-col">
              <div className="flex gap-1">
                <img src={myGoalsInactive} />
                <p className="text-base font-medium">{public_goals}</p>
              </div>
              <p className="text-xs font-normal">Total Created Goals</p>
            </div>
            <div className="flex flex-col">
              <div className="flex gap-1">
                <img src={cup} />
                <p className="text-base font-medium">{completed_goals}</p>
              </div>
              <p className="text-xs font-normal">Total Achieved Goals</p>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-sm font-semibold uppercase">Settings</p>
            <div className="border border-grey-2 bg-card text-normal text-sm p-5 rounded-10px gap-5 flex flex-col cursor-pointer">
              {options.map((option, index) => {
                const isLastOption = index === 3
                return (
                  <div
                    className={`flex items-center ${
                      isLastOption ? "gap-2 lg:hidden" : "justify-between"
                    }`}
                    onClick={() => handleOptionClick(index, option)}
                  >
                    {isLastOption && <img src={logout} />}
                    <p
                      className={`${
                        selectedOption === option ? "font-bold" : "font-normal"
                      } ${
                        isLastOption ? "text-accent-2" : "text-grey-1"
                      } text-sm`}
                    >
                      {option}
                    </p>
                    {!isLastOption && (
                      <KeyboardArrowRightIcon className="text-grey-200" />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-5 px-5 overflow-y-scroll scrollbar-hide mb-1">
          {selectedOption === "Password" ? (
            <Password setSelectedOption={setSelectedOption} />
          ) : (
            selectedOption === "Notifications" && (
              <Notifications setSelectedOption={setSelectedOption} />
            )
          )}
        </div>
      )}
    </PostCard>
  )
}

export default ProfileScreen
