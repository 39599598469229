import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import { IError } from "./types"
import { LoadingState, toggleLoading } from "@/slice/loading"

const axiosInstance: AxiosInstance = axios.create({
  baseURL: "https://goalasone.com/",
})

export const setAuthToken = (token: string) => {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

const failedResponse = (error: IError) => {
  if (
    error.response &&
    error.response.status &&
    error.response.status === 401
  ) {
    // Redirect to logout or perform other actions
    window.localStorage.clear()
    window.location.replace("/login")
  }
  return Promise.reject(error)
}

const request = async (
  config: AxiosRequestConfig,
  dispatch?: React.Dispatch<any>,
  key?: keyof LoadingState,
) => {
  if (dispatch && key) {
    dispatch(toggleLoading({ key, value: true }))
  }
  return axiosInstance(config)
    .then((response) => {
      if (dispatch && key) {
        dispatch(toggleLoading({ key, value: false }))
      }
      return response.data
    })
    .catch((error) => {
      if (dispatch && key) {
        dispatch(toggleLoading({ key, value: false }))
      }
      return failedResponse(error)
    })
}

export const getRequest = (
  route: string,
  params = {},
  dispatch?: React.Dispatch<any>,
  key?: keyof LoadingState,
) => {
  return request({ method: "get", url: route, params }, dispatch, key)
}

export const postRequest = (
  route: string,
  data = {},
  dispatch?: React.Dispatch<any>,
  key?: keyof LoadingState,
) => {
  return request({ method: "post", url: route, data }, dispatch, key)
}

export const deleteRequest = (
  route: string,
  data = {},
  dispatch?: React.Dispatch<any>,
  key?: keyof LoadingState,
) => {
  return request({ method: "delete", url: route, params: data }, dispatch, key)
}

export const putRequest = (
  route: string,
  data = {},
  dispatch?: React.Dispatch<any>,
  key?: keyof LoadingState,
) => {
  return request({ method: "put", url: route, data }, dispatch, key)
}

export const patchRequest = (
  route: string,
  data = {},
  dispatch?: React.Dispatch<any>,
  key?: keyof LoadingState,
) => {
  return request({ method: "patch", url: route, data }, dispatch, key)
}
