import { FeedType } from "@/types/feed"
import PostCard from "@/components/Cards/Post"
import { useState } from "react"
import userImg from "@/assets/images/noUser.svg"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import messages from "@/assets/images/messages.svg"
import FavoriteIcon from "@mui/icons-material/Favorite"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@/app/store"
import { matchingFriend } from "@/utils/friend"
import moment from "moment"
import { IconButton } from "@mui/material"
import CustomMenu from "@/components/UI/Menu"
import hide from "@/assets/images/hide.svg"
import { updateGoalUUID } from "@/slice/goal"
import { Link, useNavigate } from "react-router-dom"
import { likePost } from "@/utils/post"
import heart from "@/assets/images/heart.svg"
interface FriendGoalsCardProps {
  friendGoal: FeedType
}
const FriendGoalsCard = ({ friendGoal }: FriendGoalsCardProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    user: { uuid, image, first_name, last_name },
    friend: { friendList },
  } = useSelector((state: RootState) => state)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedOption, setSelectedOption] = useState<null | string>(null)
  const [isLiked, setIsLiked] = useState<boolean>(
    friendGoal.likes.includes(uuid),
  )
  const [showAnimation, setShowAnimation] = useState<boolean>(false)
  const [likeCount, setLikeCount] = useState(friendGoal.total_likes)
  const [commentCount, setCommentCount] = useState(
    friendGoal.total_comments ?? 0,
  )
  const postedByCurrentUser = friendGoal.posted_by === uuid
  const matchingFriendData = matchingFriend(friendList, friendGoal.posted_by)
  const navigateToGoal = () => {
    dispatch(updateGoalUUID(friendGoal.goal_uuid))
    navigate(`/goals/${friendGoal.posted_by}/${friendGoal.goal_uuid}`)
  }
  const increaseLikeCount = () => {
    setLikeCount((prev) => prev + 1)
  }
  const decreaseLikeCount = () => {
    setLikeCount((prev) => prev - 1)
  }
  const header = (
    <div className="flex items-center bg-grey-2 py-2 px-4 rounded-t gap-1 border-b border-border-1">
      <p className="text-sm font-medium w-full">
        {postedByCurrentUser
          ? `${first_name} ${last_name}`
          : matchingFriendData?.name ?? ""}{" "}
        <span className="text-xs text-grey-1 font-semibold">
          Posted and Updated
        </span>{" "}
        <span
          className="text-primary text-xs underline font-semibold cursor-pointer"
          onClick={navigateToGoal}
        >
          ({friendGoal.goal})
        </span>
      </p>
    </div>
  )

  const icons = [
    {
      heading: likeCount,
      icon: (
        <FavoriteIcon
          className={`${isLiked ? "text-red-600" : "text-border-1"}`}
        />
      ),
      cursor: "pointer",
      action: async () =>
        await likePost(
          isLiked,
          setIsLiked,
          increaseLikeCount,
          decreaseLikeCount,
          friendGoal.uuid,
          isLiked ? "unlike" : "like",
          dispatch,
          setShowAnimation,
        ),
    },
    {
      heading: commentCount,
      icon: <img src={messages} />,
      cursor: "pointer",
    },
  ]

  const footer = (
    <div className="flex items-center gap-1 md:gap-2">
      <p className="text-xs font-medium">End Date:</p>
      <p className="text-xs font-medium">
        {moment(friendGoal.target_date).format("DD/MM/YYYY")}
      </p>
    </div>
  )
  const menuItems = [
    {
      text: <p className="text-sm font-normal">Block User</p>,
      icon: <img src={hide} />,
      identifier: "hide",
    },
  ]
  return (
    <>
      <CustomMenu
        isOpen={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        items={menuItems}
        anchorEl={anchorEl}
        setSelectedOption={setSelectedOption}
      />
      <PostCard
        header={header}
        icons={icons}
        uuid={friendGoal.uuid}
        footer={footer}
        customClass="shadow-none border"
        setCommentCount={setCommentCount}
      >
        <div className="flex flex-col justify-center p-5 pb-0 bg-white">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Link
                to={
                  friendGoal.posted_by === uuid
                    ? "/profile"
                    : `/user/${friendGoal.posted_by}`
                }
              >
                <div className="w-9 h-9">
                  <img
                    src={
                      postedByCurrentUser
                        ? image ?? userImg
                        : matchingFriendData?.image || userImg
                    }
                    className="h-full w-full rounded-full"
                  />
                </div>
              </Link>
              <div className="flex gap-1 flex-col">
                <p className="text-sm font-medium">
                  {postedByCurrentUser
                    ? `${first_name} ${last_name}`
                    : matchingFriendData && matchingFriendData?.name}
                </p>
                <p className="text-xs font-semibold text-grey-1">
                  {moment(friendGoal.date_created).format("DD MMM [at] h:mm a")}
                </p>
              </div>
            </div>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              <MoreHorizIcon />
            </IconButton>
          </div>
          <div className="flex pt-5 flex-col gap-4">
            <p className="text-sm font-normal">{friendGoal.description}</p>
            <div
              className={`grid ${
                friendGoal.images.length !== 1 ? "grid-cols-2" : ""
              } gap-2 w-full relative`}
            >
              {friendGoal.images.map((image) => (
                <img
                  key={image.image_url}
                  src={image.image_url}
                  className="w-full h-auto"
                />
              ))}
              {friendGoal.images.length > 0 && (
                <div
                  className={`absolute w-full h-full ${
                    showAnimation
                      ? isLiked
                        ? "heart-animation !block"
                        : "hidden"
                      : "hidden"
                  }`}
                >
                  <img src={heart} className="w-full h-full" />
                </div>
              )}
            </div>
          </div>
        </div>
      </PostCard>
    </>
  )
}

export default FriendGoalsCard
