import CustomPopover from "@/components/UI/Popover"
import Loading from "@/components/UI/Loading"
import dummyUser from "@/assets/images/noUser.svg"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { SearchUserType } from "@/types/user"
import { RootState } from "@/app/store"
import { useNavigate } from "react-router-dom"
import SearchForm from "@/components/Forms/Search"
import cup from "@/assets/images/cup.svg"
import achievementCup from "@/assets/images/achievementCup.svg"
import Button from "@/components/UI/Button"
import { sendFriendRequest } from "@/utils/friend"
import PersonAddIcon from "@mui/icons-material/PersonAdd"

const SearchUsers = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    loading: { userSearch: userSearchLoading },
  } = useSelector((state: RootState) => state)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false)
  const toggleSearchResults = () => setShowSearchResults((prev) => !prev)
  const [usersList, setUsersList] = useState<SearchUserType[] | []>([])
  const [reset, setReset] = useState<() => void>(() => () => {})
  const navigateToProfile = (user: SearchUserType) => {
    navigate(`/user/${user.uuid}`)
    toggleSearchResults()
    reset()
  }
  const handleSearchUserClick = (user: SearchUserType) => {
    if (!user.is_friend_request_sent) {
      sendFriendRequest(user.uuid, dispatch)
      toggleSearchResults()
      reset()
    }
  }
  return (
    <>
      <CustomPopover
        isOpen={showSearchResults}
        onClose={toggleSearchResults}
        anchorEl={anchorEl}
      >
        {userSearchLoading ? (
          <div className="p-5 w-72">
            <Loading />
          </div>
        ) : usersList.length > 0 ? (
          usersList.map((user: SearchUserType) => {
            return (
              <div
                key={user.uuid}
                className="p-5 hover:bg-grey-50 flex flex-col cursor-pointer"
              >
                <div className="flex items-center justify-between w-56 md:w-96">
                  <div
                    className="flex items-center gap-2"
                    onClick={() => navigateToProfile(user)}
                  >
                    <div className="w-7 h-7">
                      <img
                        src={user.image ?? dummyUser}
                        alt="user"
                        className="h-full w-full rounded-full"
                      />
                    </div>
                    <p>{user.name}</p>
                  </div>
                  {!user.is_friend && (
                    <Button
                      iconOnlyInMobile
                      name={
                        user.is_friend_request_sent ? "Pending" : "Send Request"
                      }
                      icon={<PersonAddIcon className="text-white !text-sm" />}
                      customTextClass="text-xs"
                      customClass={`${
                        user.is_friend_request_sent
                          ? "5 bg-grey-200"
                          : "bg-parrotGreen"
                      }`}
                      onClick={() => handleSearchUserClick(user)}
                    />
                  )}
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex gap-1">
                    <img src={cup} />
                    <p className="text-base font-medium">
                      {user.total_goals ?? 0}{" "}
                      <span className="font-normal">Goals</span>
                    </p>
                  </div>
                  <div className="w-px border border-border-1 h-3.5" />
                  <div className="flex gap-1">
                    <img src={achievementCup} />
                    <p className="text-base font-medium">
                      {user.achieved_goals ?? 0}{" "}
                      <span className="font-normal">Achieved</span>
                    </p>
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div className="p-5 w-72">
            <p className="text-sm font-medium">No users found</p>
          </div>
        )}
      </CustomPopover>
      <SearchForm
        toggleSearchResults={toggleSearchResults}
        setAnchorEl={setAnchorEl}
        setReset={setReset}
        setUsersList={setUsersList}
      />
    </>
  )
}

export default SearchUsers
