import { FriendType } from "@/types/friend"
import { postRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import { Dispatch, SetStateAction } from "react"
import { ToastType } from "@/types/toast"
import { fetchFriendList, updateToastState } from "@/utils/index"

export const matchingFriend = (
  friendList: FriendType[],
  uuidToCompare: string,
) => friendList.find((friend) => friend.uuid === uuidToCompare)

export const sendFriendRequest = async (
  uuid: string,
  dispatch: Dispatch<any>,
) => {
  try {
    await postRequest(
      getRoute("friendRequestSend"),
      { uuid: uuid },
      dispatch,
      "friendRequestSend",
    )
    updateToastState(dispatch, "Friend request sent", ToastType.Success)
  } catch (err: any) {
    updateToastState(
      dispatch,
      err.response.data[0] ? err.response.data[0] : "Failed to send request",
      ToastType.Error,
    )
  }
}

export const findFriend = (friendList: FriendType[] | [], uuid: string) =>
  friendList.filter((friend: FriendType) => friend.uuid === uuid)

export const checkRequestStatus = async (
  user_uuid: string,
  setRequestAlreadySent: Dispatch<SetStateAction<boolean>>,
) => {
  try {
    await postRequest(getRoute("requestStatus"), {
      user_uuid,
    })
  } catch (err: any) {
    if (err.response.data[0] === "Friend Request Sent Already") {
      setRequestAlreadySent(true)
    } else {
      setRequestAlreadySent(false)
    }
  }
}

export const extractFirstAndLastName = (inputString: string) => {
  inputString = inputString.trim()
  const lastSpaceIndex = inputString.lastIndexOf(" ")
  if (lastSpaceIndex === -1) {
    return [inputString, ""]
  }
  const lastWord = inputString.substring(lastSpaceIndex + 1)
  const restOfString = inputString.substring(0, lastSpaceIndex)
  return [restOfString, lastWord]
}

export const unfriend = async (uuid: string, dispatch: Dispatch<any>) => {
  try {
    await postRequest(getRoute("unfriend"), { uuid }, dispatch, "unfriend")
    updateToastState(dispatch, "Unfriend successful", ToastType.Success)
    fetchFriendList(dispatch)
  } catch (err) {
    updateToastState(dispatch, "Failed to unfriend", ToastType.Error)
  }
}

export const blockUser = async (
  dispatch: Dispatch<any>,
  uuid: string,
  someFunction?: () => void,
) => {
  try {
    await postRequest(getRoute("blockUser"), { uuid }, dispatch)
    updateToastState(dispatch, "User blocked", ToastType.Success)
    fetchFriendList(dispatch)
    if (someFunction) {
      someFunction()
    }
  } catch (err) {
    updateToastState(dispatch, "Failed to block user", ToastType.Error)
  }
}
