import { ToastInfoType } from "@/types/toast"
import { Alert, Snackbar } from "@mui/material"

interface ToastProps extends ToastInfoType {}

const Toast = ({ isOpen, onClose, message, type }: ToastProps) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={1000}
      onClose={onClose}
      message={message}
    >
      <Alert severity={type} onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default Toast
