import { putRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import { RootState } from "@/app/store"
import Button from "@/components/UI/Button"
import InputField from "@/components/UI/InputField"
import { updateSelectedProfileSetting } from "@/slice/profile"
import { ToastType } from "@/types/toast"
import { validatePassword } from "@/utils/auth"
import { updateToastState } from "@/utils/index"
import { Dispatch, SetStateAction } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

type Inputs = {
  oldPassword: string
  newPassword: string
  reEnteredPassword: string
}
interface PasswordProps {
  setSelectedOption: Dispatch<SetStateAction<string>>
}

const Password = ({ setSelectedOption }: PasswordProps) => {
  const dispatch = useDispatch()
  const {
    loading: { resetPassword: resetPasswordLoading },
  } = useSelector((state: RootState) => state)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      reEnteredPassword: "",
    },
  })
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const password1Error = validatePassword(data.newPassword)
    if (password1Error) {
      updateToastState(dispatch, password1Error, ToastType.Error)
      return
    }
    if (data.newPassword === data.oldPassword) {
      updateToastState(
        dispatch,
        "New password should be different",
        ToastType.Error,
      )

      return
    }
    if (data.newPassword !== data.reEnteredPassword) {
      updateToastState(dispatch, "Passwords do not match", ToastType.Error)
      return
    }
    const dataToSend = {
      password1: data.newPassword,
      password2: data.reEnteredPassword,
      old_password: data.oldPassword,
    }
    try {
      await putRequest(
        getRoute("resetPassword"),
        dataToSend,
        dispatch,
        "resetPassword",
      )
      updateToastState(
        dispatch,
        "Password changed successfully",
        ToastType.Success,
      )

      setSelectedOption("")
    } catch (err) {
      updateToastState(dispatch, "Failed to change password", ToastType.Error)
    }
  }
  const onCancel = () => {
    setSelectedOption && setSelectedOption("")
    dispatch(updateSelectedProfileSetting(""))
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-center gap-6"
    >
      <InputField
        control={control}
        name="oldPassword"
        placeholder="Old Password"
        type="password"
        hasError={!!errors?.oldPassword}
        required={true}
        hasIcon
        customClass="!text-black !override-autofill"
      />
      <InputField
        control={control}
        name="newPassword"
        placeholder="New Password"
        type="password"
        hasError={!!errors?.newPassword}
        required={true}
        hasIcon
        customClass="!text-black !override-autofill"
      />
      <InputField
        control={control}
        name="reEnteredPassword"
        placeholder="Re-enter Password"
        type="password"
        hasError={!!errors?.reEnteredPassword}
        required={true}
        hasIcon
        customClass="!text-black !override-autofill"
      />
      <div className="w-full">
        <div className="float-right flex items-center gap-4">
          <Button
            name="Cancel"
            type="button"
            onClick={onCancel}
            customClass="!px-10 border border-grey-1 bg-white"
            customTextClass="!text-grey-1"
          />
          <Button
            name="Save changes"
            type="submit"
            loading={resetPasswordLoading}
            customClass="!px-4"
            loaderColor="white"
          />
        </div>
      </div>
    </form>
  )
}

export default Password
