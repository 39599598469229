import { IconButton, Modal, DialogProps } from "@mui/material"
import { ReactElement, ReactNode } from "react"
import close from "@/assets/images/close-circle.svg"

interface CustomModalProps {
  children: ReactElement
  isOpen: boolean
  onClose: () => void
  title: string | ReactNode
  titleBg?: string
  customClass?: string
  showHeader?: boolean
}

const CustomModal = ({
  children,
  isOpen,
  onClose,
  title,
  titleBg = "bg-grey-50",
  customClass = "h-500",
  showHeader = true,
}: CustomModalProps) => {
  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick") return
    onClose()
  }
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div
        className={`flex flex-col absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg w-72 xs:w-96 md:w-600 p-px rounded overflow-y-scroll ${customClass}`}
      >
        {showHeader && (
          <div
            className={`flex justify-between py-5 px-4 ${titleBg} items-center rounded-t-15px`}
          >
            {typeof title === "string" ? (
              <p className="text-base font-semibold">{title}</p>
            ) : (
              title
            )}
            <IconButton onClick={() => onClose()}>
              <img src={close} />
            </IconButton>
          </div>
        )}
        {children}
      </div>
    </Modal>
  )
}

export default CustomModal
