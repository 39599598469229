import { FriendRequestType } from "@/types/friendRequest"
import { postRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import { useDispatch } from "react-redux"
import Toast from "@/components/UI/Toast"
import { useState } from "react"
import { ToastInfoType, ToastType } from "@/types/toast"
import { fetchFriendRequestList } from "@/utils/updateRedux"
import PostCard from "@/components/Cards/Post"
import cup from "@/assets/images/cup.svg"
import boldCup from "@/assets/images/boldCup.svg"
import declineRequest from "@/assets/images/declineRequest.svg"
import acceptRequest from "@/assets/images/acceptRequest.svg"
import acceptFriendRequestSVG from "@/assets/images/acceptFriendRequest.svg"
import declineFriendRequest from "@/assets/images/declineFriendRequest.svg"
import userImg from "@/assets/images/noUser.svg"
import { fetchFriendList, updateToastState } from "@/utils/index"

interface FriendRequestCardProps {
  friendRequest: FriendRequestType
  fetchFriendRequests?: () => void
}

const FriendRequestCard = ({
  friendRequest,
  fetchFriendRequests,
}: FriendRequestCardProps) => {
  const dispatch = useDispatch()
  const acceptFriendRequest = async (action: string) => {
    try {
      await postRequest(
        getRoute("friendRequestRespond"),
        { uuid: friendRequest.from_user.uuid, action },
        dispatch,
        "friendRequestRespond",
      )
      updateToastState(dispatch, "Friend request accepted", ToastType.Success)
      fetchFriendRequestList(dispatch)
      fetchFriendList(dispatch)
      if (fetchFriendRequests) {
        fetchFriendRequests()
      }
    } catch (err) {
      updateToastState(dispatch, "Failed to accept request", ToastType.Error)
    }
  }

  const footer = (
    <div className="flex items-center gap-4 w-full">
      <div className="flex items-center gap-2">
        <img src={cup} />
        <p className="font-normal text-sm">
          {friendRequest.from_user.total_goals ?? 0} Goals
        </p>
      </div>
      <div className="h-5 w-0.5 border" />
      <div className="flex items-center gap-2">
        <img src={boldCup} />
        <p className="font-normal text-sm">
          {friendRequest.from_user.achieved_goals ?? 0} Achieved
        </p>
      </div>
    </div>
  )
  return (
    <PostCard widthClass="w-full" footer={footer} customClass="!m-0">
      <div className="flex flex-col p-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="w-9 h-9">
              <img
                src={
                  friendRequest.from_user.image
                    ? friendRequest.from_user.image
                    : userImg
                }
                className="h-full w-full rounded-full"
              />
            </div>
            <div className="flex gap-1 flex-col">
              <p className="text-sm font-medium">
                {friendRequest.from_user.name}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-8 h-full lg:hidden">
              <img
                src={declineRequest}
                className="cursor-pointer w-full"
                onClick={() => acceptFriendRequest("reject")}
              />
            </div>
            <div className="lg:w-24 hidden lg:block">
              <img
                src={declineFriendRequest}
                className="cursor-pointer w-full h-full"
                onClick={() => acceptFriendRequest("reject")}
              />
            </div>
            <div className="w-8 h-full lg:hidden">
              <img
                src={acceptRequest}
                className="cursor-pointer w-full"
                onClick={() => acceptFriendRequest("accept")}
              />
            </div>
            <div className="lg:w-24 hidden lg:block">
              <img
                src={acceptFriendRequestSVG}
                className="cursor-pointer w-full h-full"
                onClick={() => acceptFriendRequest("accept")}
              />
            </div>
          </div>
        </div>
      </div>
    </PostCard>
  )
}

export default FriendRequestCard
