import userImg from "@/assets/images/noUser.svg"
import CustomMenu from "@/components/UI/Menu"
import { CommentType } from "@/types/comment"
import { getDuration } from "@/utils/date"
import CircleIcon from "@mui/icons-material/Circle"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { IconButton } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@/app/store"
import { blockUser, unfriend } from "@/utils/friend"

interface CommentCardProps {
  comment: CommentType
}

const CommentCard = ({ comment }: CommentCardProps) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedOption, setSelectedOption] = useState<null | string>(null)
  const menuItems = [
    // "delete comment",
    `unfollow ${comment.user.name}`,
    `block ${comment.user.name}`,
  ]
  const userData = useSelector((state: RootState) => state.user)
  const friendData = useSelector((state: RootState) => state.friend)

  const loadImage = () => {
    if (userData.uuid === comment.user.uuid) {
      return userData.image ? userData.image : userImg
    } else {
      const friend = friendData.friendList.find(
        (item) => item.uuid === comment.user.uuid,
      )
      if (friend) {
        return friend.image ? friend.image : userImg
      } else {
        return userImg
      }
    }
  }
  useEffect(() => {
    if (selectedOption === `unfollow ${comment.user.name}`) {
      unfriend(comment.user.uuid, dispatch)
    }
    if (selectedOption === `block ${comment.user.name}`) {
      blockUser(dispatch, comment.user.uuid)
    }
  }, [selectedOption])
  return (
    <>
      <CustomMenu
        isOpen={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        items={menuItems}
        anchorEl={anchorEl}
        setSelectedOption={setSelectedOption}
      />
      <div className="flex flex-col bg-grey-2 px-5 py-3 rounded gap-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className="w-7 h-7">
              <img src={loadImage()} className="rounded-full w-full h-full" />
            </div>
            <div className="flex items-center gap-2">
              <p className="font-medium text-xs">{comment.user.name}</p>
              <div className="flex items-center gap-2">
                <CircleIcon className="!text-[3px] text-body" />
                <p className="font-normal text-[10px] text-body">
                  {getDuration(comment.submit_date)}
                </p>
              </div>
            </div>
          </div>
          <div>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              <MoreHorizIcon />
            </IconButton>
          </div>
        </div>
        <p className="font-normal text-sm">{comment.comment}</p>
      </div>
    </>
  )
}

export default CommentCard
