import moment from "moment"

export const getDuration = (providedTimestamp: number) => {
  const currentTime = moment.utc()
  const providedTime = moment.utc(providedTimestamp)
  const differenceInMinutes = currentTime.diff(providedTime, "minutes")
  let formattedDifference
  if (differenceInMinutes < 60) {
    formattedDifference = `${differenceInMinutes} minutes ago`
  } else if (differenceInMinutes < 1440) {
    const differenceInHours = Math.floor(differenceInMinutes / 60)
    formattedDifference = `${differenceInHours} hour(s) ago`
  } else {
    const differenceInDays = Math.floor(differenceInMinutes / 1440)
    formattedDifference = `${differenceInDays} day(s) ago`
  }
  return formattedDifference
}
