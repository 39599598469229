import { ReactNode } from "react"

interface CardsContainerProps {
  children: ReactNode
}

const CardsContainer = ({ children }: CardsContainerProps) => {
  return (
    <div className="flex flex-col gap-4 overflow-y-scroll scrollbar-hide mb-5">
      {children}
    </div>
  )
}

export default CardsContainer
