import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import counterReducer from "../features/counter/counterSlice"
import authReducer from "@/slice/auth"
import loadingReducer from "@/slice/loading"
import goalReducer from "@/slice/goal"
import friendReducer from "@/slice/friend"
import userReducer from "@/slice/user"
import profileReducer from "@/slice/profile"
import layoutReducer from "@/slice/layout"
import notificationsReducer from "@/slice/notifications"
import toastReducer from "@/slice/toast"

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    loading: loadingReducer,
    goal: goalReducer,
    friend: friendReducer,
    user: userReducer,
    profile: profileReducer,
    layout: layoutReducer,
    notifications: notificationsReducer,
    toast: toastReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
