import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface NotificationsState {
  isOpen: boolean
  selectedNotificationType: string
}

const initialState: NotificationsState = {
  isOpen: false,
  selectedNotificationType: "",
}

export const profileSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    toggleNotifications: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    updateSelectedNotificationType: (state, action: PayloadAction<string>) => {
      state.selectedNotificationType = action.payload
    },
  },
})

export const { toggleNotifications, updateSelectedNotificationType } =
  profileSlice.actions
export default profileSlice.reducer
