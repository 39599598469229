import { Popover } from "@mui/material"
import { ReactNode } from "react"

interface CustomPopoverProps {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  anchorEl: HTMLElement | null
  customClass?: string
}

const CustomPopover = ({
  children,
  isOpen,
  onClose,
  anchorEl,
  customClass,
}: CustomPopoverProps) => {
  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      classes={{ paper: `!rounded-lg ${customClass}` }}
    >
      {children}
    </Popover>
  )
}

export default CustomPopover
