import CustomMenu from "@/components/UI/Menu"
import { useState } from "react"
import GoalForm from "@/components/Forms/Goal"
import moment from "moment"
import PostForm from "@/components/Forms/Post"
import CustomModal from "@/components/UI/Modal"
import PostCard from "@/components/Cards/Post"
import { useDispatch, useSelector } from "react-redux"
import global from "@/assets/images/global.svg"
import lock from "@/assets/images/lock.svg"
import Button from "@/components/UI/Button"
import update from "@/assets/images/update.svg"
import edit from "@/assets/images/edit.svg"
import { RootState } from "@/app/store"
import userImg from "@/assets/images/noUser.svg"
import liked from "@/assets/images/liked.svg"
import recent from "@/assets/images/recent.svg"
import comment from "@/assets/images/comment.svg"
import { breakTextIntoSegments } from "@/utils/goal"
import GoalDetails from "@/components/Cards/Goal/Details"
import { GoalCardProps } from "@/types/goalCard"
import { fetchAllGoals } from "@/utils/updateRedux"

const GoalCard = ({ goal, uuid, showButtons = true }: GoalCardProps) => {
  const dispatch = useDispatch()
  const {
    user: { image, first_name, last_name },
  } = useSelector((state: RootState) => state)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isGoalModalOpen, setIsGoalModalOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedOption, setSelectedOption] = useState<string>("")
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [showReadMore, setShowReadMore] = useState<boolean>(true)
  const [showCompleted, setShowCompleted] = useState<boolean>(false)
  const toggleReadMore = () => setShowReadMore((prev) => !prev)
  const toggleModal = () => setIsModalOpen((prev) => !prev)
  const toggleGoalModal = () => setIsGoalModalOpen((prev) => !prev)
  const menuItems = [
    {
      text: <p className="text-sm font-normal">Most Liked</p>,
      icon: <img src={liked} />,
      identifier: "most_likes",
    },
    {
      text: <p className="text-sm font-normal">Most Recent</p>,
      icon: <img src={recent} />,
      identifier: "most_recent",
    },
    {
      text: <p className="text-sm font-normal">Most Comments</p>,
      icon: <img src={comment} />,
      identifier: "most_commented",
    },
  ]
  const header = (
    <div className="flex flex-col xs:flex-row xs:items-center gap-2 md:gap-0 justify-between bg-grey-2 py-2 px-4 rounded-t border-b border-border-1">
      <div className="flex items-center gap-1">
        <p className="text-xs font-medium text-header opacity-50">
          Start & Due Date:
        </p>
        <p className="text-xs font-normal">
          {moment(goal.start_date).format("D MMMM")},{" "}
          {moment(goal.target_date).format("D MMMM")}
        </p>
      </div>
      <div className="flex items-center gap-1">
        <p className="text-xs font-medium text-header opacity-50">Status:</p>
        <p className="text-xs font-normal text-accent-1">
          {goal.current_status} {goal.progress}%
        </p>
      </div>
    </div>
  )
  const footer = (
    <div className="flex items-center w-full justify-between gap-2 md:gap-0">
      <div className="flex items-center gap-1 w-full xs:w-auto">
        <p className="text-xs font-medium">Total Posts:</p>
        <p className="text-xs font-normal">{goal.total_posts}</p>
      </div>
      {!uuid && showButtons && (
        <div className="flex items-center gap-2 md:gap-4">
          <Button
            name="Edit Goal"
            customClass="bg-grey-2 border md:!px-10 md:!py-3.5 py-3.5 px-3.5 xs:p-3"
            customTextClass="!text-grey-1 text-xs font-semibold"
            onClick={() => toggleGoalModal()}
            icon={<img src={edit} className="w-10 xs:w-3 xs:h-3" />}
            iconOnlyInMobile
          />
          <Button
            name="Post Update"
            customClass="!rounded-full text-xs !font-medium md:!px-7 py-3.5 px-3.5 xs:p-3"
            onClick={() => toggleModal()}
            icon={<img src={update} className="w-10 xs:w-3 xs:h-3" />}
            iconOnlyInMobile
          />
        </div>
      )}
    </div>
  )
  const title = (
    <div className="flex items-center gap-4">
      <div className="w-7 h-7">
        <img src={image ?? userImg} className="h-full w-full rounded-full" />
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-sm md:text-base font-medium">
          {first_name} {last_name}
        </p>
        <p className="text-xs font-medium text-grey-1">Post To Anyone</p>
      </div>
    </div>
  )
  return (
    <>
      <CustomMenu
        isOpen={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        items={menuItems}
        anchorEl={anchorEl}
        setSelectedOption={setSelectedOption}
      />
      <CustomModal
        isOpen={isGoalModalOpen}
        title="UPDATE GOAL"
        onClose={() => {
          toggleGoalModal()
          setShowCompleted(false)
        }}
        showHeader={!showCompleted}
      >
        <GoalForm
          goal={goal}
          onClose={() => {
            toggleGoalModal()
            setShowCompleted(false)
          }}
          setShowCompleted={setShowCompleted}
          showCompleted={showCompleted}
        />
      </CustomModal>
      <CustomModal
        isOpen={isModalOpen}
        onClose={() => {
          toggleModal()
          setShowCompleted(false)
          fetchAllGoals(dispatch)
        }}
        title={title}
        titleBg="bg-white"
        customClass="h-auto"
        showHeader={!showCompleted}
      >
        <PostForm
          goal={goal}
          onClose={() => {
            setSelectedOption("")
            toggleModal()
            setShowCompleted(false)
          }}
          showCompleted={showCompleted}
          setShowCompleted={setShowCompleted}
        />
      </CustomModal>
      <PostCard
        header={header}
        footer={showDetails ? <></> : footer}
        footerCustomClass="pt-0 px-3 md:p-5"
        customClass="shadow-none border"
      >
        <div className="flex p-2 md:p-5 pb-0 md:pb-0 gap-2">
          {goal.photo_upload && (
            <img src={goal.photo_upload} className="h-14 w-20" />
          )}
          <div className="w-4/6 md:w-3/4 flex flex-col gap-3">
            <div className="flex items-center gap-2">
              <p className="font-medium text-base">{goal.title}</p>
              <div className="rounded-full bg-grey-2 p-1">
                <img src={goal.is_private ? lock : global} />
              </div>
            </div>
            <p
              className="text-xs font-normal cursor-pointer"
              onClick={() => {
                setShowDetails((prev) => !prev)
              }}
            >
              {goal.description.length > 65
                ? showReadMore
                  ? breakTextIntoSegments(goal.description)
                  : `${goal.description} `
                : goal.description}
              {goal.description.length > 65 && (
                <span
                  className="text-xs font-medium text-primary cursor-pointer"
                  onClick={toggleReadMore}
                >
                  {showReadMore ? "Read More" : "Show Less"}
                </span>
              )}
            </p>
          </div>
        </div>
        {showDetails && (
          <div className="flex flex-col gap-4 mt-5">
            <div className="flex items-center justify-between px-5 border-t pt-5">
              <p className="text-base font-medium">All Posts</p>
              <p
                className="font-semibold text-sm text-primary cursor-pointer"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                Filter posts
              </p>
            </div>
            <GoalDetails
              goal={goal}
              selectedOption={selectedOption}
              isFriendGoalPostList={!!uuid}
            />
          </div>
        )}
      </PostCard>
    </>
  )
}

export default GoalCard
