interface AuthHeaderProps {
  greetingText: string
  subGreetingText: string
}

const AuthHeader = ({ greetingText, subGreetingText }: AuthHeaderProps) => {
  return (
    <div className="flex flex-col gap-1">
      <p className="font-semibold text-2xl text-center md:text-left">
        {greetingText}
      </p>
      <p className="font-normal text-sm text-center md:text-left">
        {subGreetingText}
      </p>
    </div>
  )
}

export default AuthHeader
