export const breakTextIntoSegments = (text: string) => {
  const maxWordsPerSegment = 65
  const words = text.split(" ")
  const segments = []
  let currentSegment = ""
  for (const word of words) {
    if ((currentSegment + " " + word).length <= maxWordsPerSegment) {
      currentSegment =
        currentSegment === "" ? word : currentSegment + " " + word
    } else {
      segments.push(currentSegment + "... ")
      currentSegment = word
    }
  }

  if (currentSegment !== "") {
    segments.push(currentSegment)
  }

  return segments[0]
}
