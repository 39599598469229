import { ReactElement, ReactNode, cloneElement, useState } from "react"
import { Controller, Control, FieldValues, Path } from "react-hook-form"
import passwordHide from "@/assets/images/passwordHide.svg"
import search from "@/assets/images/search.svg"

interface InputFieldProps<T extends FieldValues> {
  control: Control<T>
  name: keyof T
  label?: string
  type?: string
  hasError: boolean
  required: boolean
  children?: ReactElement
  helperText?: string
  isRow?: boolean
  customClass?: string
  placeholder?: string
  multiline?: boolean
  rows?: number
  hasIcon?: boolean
  hasBorder?: boolean
  width?: string
  padding?: string
  htmlFor?: string
  customImage?: ReactNode
  multiple?: boolean
  verticalAlignment?: string
  gap?: string
  wrapperCustomClass?: string
  onIconClick?: () => void
  onFocus?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const InputField = <T extends FieldValues>({
  control,
  name,
  label,
  type = "text",
  hasError,
  required,
  children,
  isRow,
  helperText,
  customClass,
  placeholder,
  multiline,
  rows,
  hasIcon = false,
  hasBorder = true,
  width,
  padding = "px-3 py-1",
  htmlFor,
  customImage,
  multiple,
  verticalAlignment,
  gap,
  wrapperCustomClass,
  onIconClick = () => {},
  onFocus = () => {},
  onBlur = () => {},
}: InputFieldProps<T>) => {
  const [inputType, setInputType] = useState<string>(type)
  const [isActive, setIsActive] = useState<boolean>(false)
  const toggleInputType = () => {
    setInputType((prev) => (prev === "text" ? "password" : "text"))
  }
  return (
    <div
      className={`flex ${!isRow ? "flex-col" : "justify-between"} w-full ${
        hasBorder && `border ${isActive ? "border-grey-1" : "border-grey-200"}`
      } rounded-10px ${padding} ${width} ${verticalAlignment} ${gap} ${wrapperCustomClass}`}
    >
      <div className="flex flex-col gap-2">
        <label
          className={`text-xs font-normal text-header ${
            customImage && "cursor-pointer"
          }`}
          htmlFor={htmlFor}
        >
          {label}
          {customImage && (
            <Controller
              name={name as Path<T>}
              control={control}
              rules={{ required: required }}
              render={({ field }) => (
                <>
                  <input
                    id={htmlFor}
                    type={inputType}
                    multiple={multiple}
                    {...field}
                    placeholder={placeholder}
                    className={`${customClass} text-body focus:outline-none w-[99%] ${
                      isActive ? "border-blue-500" : ""
                    }`}
                    min={
                      type === "date"
                        ? new Date().toISOString().split("T")[0]
                        : undefined
                    }
                    onFocus={(e) => {
                      setIsActive(true)
                      onFocus(e)
                    }}
                    onBlur={(e) => {
                      setIsActive(false)
                      onBlur(e)
                    }}
                  />
                  {customImage}
                </>
              )}
            />
          )}
        </label>
        {helperText && (
          <p className="text-grey-1 text-xs font-normal italic">{helperText}</p>
        )}
      </div>
      {!customImage && (
        <div className="flex items-center w-full">
          <Controller
            name={name as Path<T>}
            control={control}
            rules={{ required: required }}
            render={({ field }) =>
              children ? (
                cloneElement(children, {
                  ...children.props,
                  className: `${customClass} ${
                    children.props.className || ""
                  } ${isActive ? "!border-grey-1" : "border-grey-200"}`,
                  checked: field.value,
                  onChange: field.onChange,
                  onFocus: (
                    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
                  ) => {
                    setIsActive(true)
                    onFocus(e)
                  },
                  onBlur: (
                    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
                  ) => {
                    setIsActive(false)
                    onBlur(e)
                  },
                })
              ) : multiline ? (
                <textarea
                  {...field}
                  placeholder={placeholder}
                  className={`${customClass} text-body focus:outline-none ${width} ${
                    isActive ? "border-blue-500" : ""
                  }`}
                  rows={rows}
                  onFocus={(e) => {
                    setIsActive(true)
                    onFocus(e)
                  }}
                  onBlur={(e) => {
                    setIsActive(false)
                    onBlur(e)
                  }}
                />
              ) : (
                <>
                  <input
                    id={htmlFor}
                    type={inputType}
                    {...field}
                    placeholder={placeholder}
                    className={`${customClass} text-body focus:outline-none w-[99%] ${
                      isActive ? "border-blue-500" : ""
                    }`}
                    min={
                      type === "date"
                        ? new Date().toISOString().split("T")[0]
                        : undefined
                    }
                    onFocus={(e) => {
                      setIsActive(true)
                      onFocus(e)
                    }}
                    onBlur={(e) => {
                      setIsActive(false)
                      onBlur(e)
                    }}
                  />
                  {customImage && customImage}
                </>
              )
            }
          />
          {hasIcon && (
            <img
              src={type === "password" ? passwordHide : search}
              onClick={type === "password" ? toggleInputType : onIconClick}
              className="cursor-pointer"
            />
          )}
        </div>
      )}
      {hasError && (
        <span className="text-red-500 text-sm">This field is required</span>
      )}
    </div>
  )
}

export default InputField
