import { ToastInfoType } from "@/types/toast"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface ToastState {
  toastInfo: ToastInfoType | null
}

const initialState: ToastState = {
  toastInfo: null,
}

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    updateToastInfo: (state, action: PayloadAction<ToastInfoType | null>) => {
      state.toastInfo = action.payload
    },
    closeToast: (state) => {
      if (state.toastInfo) {
        state.toastInfo = { ...state.toastInfo, isOpen: false }
      } else {
        state.toastInfo = null
      }
    },
  },
})

export const { updateToastInfo, closeToast } = toastSlice.actions
export default toastSlice.reducer
