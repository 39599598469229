import { AuthCardType } from "@/types/auth";

export const authCards: AuthCardType[] = [
  {
    name: "Godfrey John",
    text: "Starting my work-out journey to loose some weights today. I feel pumped already",
    color: "bg-white",
  },
  {
    name: "Selina Hanan",
    text: "On the journey to perfect my copy-writing skills. I'll be watching two youtube videos daily",
    color: "bg-pink-card",
  },
  {
    name: "Josh Elingad",
    text: "Need to shred my muscles to look trimmer. I'll be going a 30 days plank push-ups.",
    color: "bg-purple-card",
  },
]

export const validatePassword = (password: string) => {
  if (password.length < 8) {
    return "Password must be at least 8 characters"
  }
  if (!/[A-Z]/.test(password)) {
    return "Password must contain at least one capital letter"
  }
  if (!/[\W_]/.test(password)) {
    return "Password must contain at least one special character"
  }
  return null
}
