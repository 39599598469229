import { postRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import { RootState } from "@/app/store"
import Button from "@/components/UI/Button"
import InputField from "@/components/UI/InputField"
import { SubmitHandler, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import send from "@/assets/images/send-2.svg"
import { Dispatch, SetStateAction } from "react"
import { updateToastState } from "@/utils/index"
import { ToastType } from "@/types/toast"

type Inputs = {
  comment: string
}

interface CommentFormProps {
  uuid: string
  setCommentCount: Dispatch<SetStateAction<number>>
  fetchCommentList: () => void
}

const CommentForm = ({
  uuid,
  setCommentCount,
  fetchCommentList,
}: CommentFormProps) => {
  const dispatch = useDispatch()
  const { goalPostComment: goalPostCommentLoading } = useSelector(
    (state: RootState) => state.loading,
  )
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      comment: "",
    },
  })
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await postRequest(
        getRoute("goalPostComment"),
        {
          goal_post: uuid,
          comment: data.comment,
        },
        dispatch,
        "goalPostComment",
      )
      setCommentCount((prev) => prev + 1)
      fetchCommentList()
      reset()
      updateToastState(dispatch, "Comment submitted", ToastType.Success)
    } catch (err) {
      updateToastState(dispatch, "Failed to comment", ToastType.Error)
    }
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex items-center gap-2 p-2 px-5 border-t"
    >
      <InputField
        name="comment"
        hasError={!!errors.comment}
        control={control}
        required
        placeholder="Add comment"
        customClass="border-0 shadow-none rounded-none !p-1 md:!p-2 !text-black bg-grey-2"
        wrapperCustomClass="border-0 bg-grey-2"
      />
      <Button
        name={<img src={send} />}
        type="submit"
        loading={goalPostCommentLoading}
        customClass="!p-3.5 rounded-lg"
        loaderColor="white"
        loaderSize="1.1rem"
      />
    </form>
  )
}

export default CommentForm
