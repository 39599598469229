import dummyUser1 from "@/assets/images/dummyUser1.svg"
import dummyUser2 from "@/assets/images/dummyUser2.svg"
import dummyUser3 from "@/assets/images/dummyUser3.svg"
import { AuthCardType } from "@/types/auth"

interface AuthCardProps {
  card: AuthCardType
  index: number
}

const AuthCard = ({ card, index }: AuthCardProps) => {
  const userImages = [dummyUser1, dummyUser2, dummyUser3]
  const image = userImages[index % userImages.length]
  return (
    <div className={`w-8/12 rounded p-5 ${card.color} flex flex-col gap-4`}>
      <div className="flex items-center gap-4">
        <img src={image} height={40} width={40} />
        <div className="flex flex-col gap-1">
          <p className="font-medium text-xs">{card.name}</p>
        </div>
      </div>
      <p className="font-normal text-sm">{card.text}</p>
    </div>
  )
}

export default AuthCard
