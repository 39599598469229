import { ProfileInfoType } from "@/types/user"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface UserState extends ProfileInfoType {}

const initialState: UserState = {
  first_name: "",
  last_name: "",
  uuid: "",
  completed_goals: 0,
  public_goals: 0,
  image: "",
  cover_image: "",
  bio: "",
  deleted_goals: 0,
  in_progress_goals: 0,
  total_goals: 0,
}

export const goalSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateProfile: (state, action: PayloadAction<ProfileInfoType>) => {
      state.first_name = action.payload.first_name
      state.last_name = action.payload.last_name
      state.uuid = action.payload.uuid
      state.completed_goals = action.payload.completed_goals
      state.total_goals = action.payload.total_goals
      state.deleted_goals = action.payload.deleted_goals
      state.in_progress_goals = action.payload.in_progress_goals
      state.public_goals = action.payload.public_goals
      state.image = action.payload.image
      state.bio = action.payload.bio ?? ""
      state.cover_image = action.payload.cover_image
    },
  },
})

export const { updateProfile } = goalSlice.actions
export default goalSlice.reducer
