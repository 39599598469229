import PostCard from "@/components/Cards/Post"
import edit from "@/assets/images/edit.svg"
import global from "@/assets/images/global.svg"
import lock from "@/assets/images/lock.svg"
import { Divider } from "@mui/material"
import Button from "@/components/UI/Button"
import CustomModal from "../UI/Modal"
import GoalForm from "@/components/Forms/Goal"
import { useState } from "react"
import { togglePrivacy } from "@/slice/goal"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@/app/store"

const ProfileStats = () => {
  const dispatch = useDispatch()
  const { privacy } = useSelector((state: RootState) => state.goal)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [showCompleted, setShowCompleted] = useState<boolean>(false)
  const toggleModal = () => setIsOpen((prev) => !prev)
  return (
    <>
      <CustomModal
        isOpen={isOpen}
        onClose={() => {
          toggleModal()
          setShowCompleted(false)
        }}
        title="ADD NEW GOAL"
        showHeader={!showCompleted}
      >
        <GoalForm
          onClose={() => {
            toggleModal()
            setShowCompleted(false)
          }}
          showCompleted={showCompleted}
          setShowCompleted={setShowCompleted}
        />
      </CustomModal>
      <PostCard widthClass="w-72">
        <div className="flex items-center flex-col p-5 w-full">
          <div className="flex justify-between items-center w-full">
            <p className="text-sm font-normal">Goal Privacy</p>
            {/* <img src={edit} /> */}
          </div>
          <div
            className="flex items-center gap-2 w-full"
            onClick={() => dispatch(togglePrivacy())}
          >
            <p className="text-lg font-semibold">
              {privacy ? "Private" : "Public"}
            </p>
            <img
              src={privacy ? lock : global}
              className="p-2 bg-grey-2 rounded-50px cursor-pointer"
            />
          </div>
          <div className="w-full mt-2">
            <Divider />
          </div>
          <Button
            name="Add New Goal"
            customClass="text-sm !rounded-50px !font-medium mt-3 bg-parrotGreen"
            fullWidth
            onClick={toggleModal}
          />
        </div>
      </PostCard>
    </>
  )
}

export default ProfileStats
