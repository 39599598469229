import { IconType } from "@/types/icon"

interface IconProps extends IconType {}

const Icon = ({ heading, icon, action, cursor }: IconProps) => {
  return (
    <div
      className={`flex items-center rounded-full py-2.5 px-4 gap-2 bg-grey-2 ${
        cursor && `cursor-${cursor}`
      }`}
      onClick={() => action && action()}
    >
      <p className="font-semibold text-base">{heading}</p>
      {icon}
    </div>
  )
}

export default Icon
