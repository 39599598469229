import AuthHeader from "@/components/AuthHeader"
import SignupForm from "@/components/Forms/Signup"
import AuthLayout from "@/components/Layout/Auth"
import Button from "@/components/UI/Button"
import { useState } from "react"
import { Link } from "react-router-dom"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import AuthFooter from "@/components/AuthFooter"

const RegisterScreen = () => {
  const [currentStep, setCurrentStep] = useState<number>(1)
  const toggleStep = () =>
    setCurrentStep((prev) => (prev === 1 ? prev + 1 : prev - 1))
  return (
    <AuthLayout>
      <div className="flex flex-col gap-19 md:my-2 lg:my-8 px-5 md:px-16 overflow-y-scroll scrollbar-hide">
        <AuthHeader
          greetingText="Create your account"
          subGreetingText="Please enter your details to sign in."
        />
        <SignupForm />
        {/* <AuthFooter action="Sign Up" /> */}
      </div>
      <p className="font-normal text-sm text-center">
        Already have an account?
        <Link to="/login">
          <span className="font-semibold text-sm ml-2">Log In</span>
        </Link>
      </p>
    </AuthLayout>
  )
}

export default RegisterScreen
